import { useMemo } from 'react';
import Lottie from 'lottie-react';
import { Button } from '../../../../../../../../../shared/Button';
import { Modal } from '../../../../../../../../../shared/Modal';
import sendingAnimation from '../../../../../../../../../assets/sendingLottie.json';
import successAnimation from '../../../../../../../../../assets/successLottie.json';
import errorAnimation from '../../../../../../../../../assets/errorLottie.json';

interface Props {
  status: 'sending' | 'success' | 'error';
  isOpen: boolean;
  onClose: () => void;
  container: Element | DocumentFragment;
}

export const ApproveModal = ({ status, isOpen, onClose, container }: Props) => {
  const handleStep = () => {
    if (status === 'sending') {
      return (
        <div style={{ width: '300px', height: '300px', margin: 'auto' }}>
          <Lottie animationData={sendingAnimation} />
        </div>
      );
    }
    if (status === 'success') {
      return (
        <div style={{ width: '300px', height: '300px', margin: 'auto' }}>
          <Lottie animationData={successAnimation} loop={false} />
        </div>
      );
    }
    if (status === 'error') {
      return (
        <div style={{ width: '300px', height: '300px', margin: 'auto' }}>
          <Lottie animationData={errorAnimation} />
        </div>
      );
    }
  };

  const handleModalTitle = useMemo(() => {
    if (status === 'sending') return 'Enviando Resposta';
    if (status === 'success') return 'Resposta Enviada';
    return 'Falha ao enviar Resposta';
  }, [status]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={handleModalTitle}
      size="large"
      container={container}
      footer={
        status === 'success' || status === 'error' ? (
          <Button
            variant={status === 'success' ? 'register' : 'cancel'}
            handleClick={onClose}
          >
            Fechar
          </Button>
        ) : null
      }
    >
      {handleStep()}
    </Modal>
  );
};
