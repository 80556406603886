import { PlusCircle, PlusSquare } from '@phosphor-icons/react';
import { useState } from 'react';
import styles from './created-quotes.module.css';
import { ListTable } from '../../../../../../shared/ListTable';
import { CreatedQuotesRow } from '../../components/CreatedQuotesRow';
import { ViewCreatedQuotation } from '../../components/modals/ViewCreatedQuotation';
import { Button } from '../../../../../../shared/Button';
import { CreateQuotation } from '../../components/modals/CreateQuotation';
import createdQuotationsIcon from '../../../../../../assets/icons/created-quotations.svg';
import { useCreatedQuotation } from './hooks/useCreatedQuotation';
import { FinishModal } from '../../components/modals/FinishModal';
import { useChangeQuotationState } from '../../hooks/useChangeQuotationState';
import { useResponsive } from '../../../../../../hooks/useResponsive';
import { FilterComponent } from '../../../../../../shared/FilterComponent';
import { RedoQuotationModal } from '../../components/modals/RedoQuotationModal';
import { useCreatedQuotationData } from './hooks/useCreatedQuotationData';
import { Quotation } from '../../../../../../@types/interface';
import { EditQuotation } from '../../components/modals/EditQuotation';
import { IncreaseQuotationHoursModal } from '../../components/modals/IncreaseQuotationHoursModal';
import { QuotationFilterOptions } from '../../interface';
import { deleteQuotationController } from '../../controller';
import { AddCompanyModal } from '../../components/modals/AddCompanyModal';

export const CreatedQuotations = () => {
  const {
    createdQuotations,
    fetchQuotationById,
    fetchQuotationsByFilter,
    handleFinishQuotation,
    isLoading,
    page,
    searchedQuotation,
    searchedQuotations,
    loadMoreData,
    setSearchedQuotation,
    handleIncrementHours,
    isIncrementingHours,
  } = useCreatedQuotationData();

  const {
    handleRowClick,
    isCreateQuotationModalOpen,
    setIsCreateQuotationModalOpen,
    setSelectedQuotation,
    selectedQuotation,
    isViewQuotationModalOpen,
    setIsViewQuotationModalOpen,
    isEditingQuotationModalOpen,
    setIsEditingQuotationModalOpen,
    filterType,
    setFilterType,
    handleDateSearch,
    handleSearch,
    filterValue,
    isRedoQuotationModalOpen,
    setIsRedoQuotationModalOpen,
    isIncreaseHoursModalOpen,
    setIsIncreaseHoursModalOpen,
  } = useCreatedQuotation({
    fetchQuotationById,
    fetchQuotationsByFilter,
  });

  const {
    changeQuotationState,
    isChangingQuotationModalOpen,
    isChangingQuotationState,
    handleFinishModal,
  } = useChangeQuotationState({
    onChangeState: (res: Quotation) =>
      handleFinishQuotation(res, () => {
        // if is redoing a quotation will need selectedQuotation state
        if (!isRedoQuotationModalOpen) setSelectedQuotation(null);
      }),
  });
  const { sizeMobile } = useResponsive();
  const [isAddCompanyModalOpen, setIsAddCompanyModalOpen] = useState(false);

  return (
    <>
      <AddCompanyModal
        isOpen={isAddCompanyModalOpen}
        onClose={() => setIsAddCompanyModalOpen(false)}
        defaultQuotation={selectedQuotation}
      />
      <IncreaseQuotationHoursModal
        isOpen={isIncreaseHoursModalOpen && !!selectedQuotation}
        onClose={() => setIsIncreaseHoursModalOpen(false)}
        quotation={selectedQuotation}
        onSave={async (quotation, hours) => {
          await handleIncrementHours(quotation, hours);
          setIsIncreaseHoursModalOpen(false);
        }}
        isIncrementingHours={isIncrementingHours}
      />
      <RedoQuotationModal
        isOpen={isRedoQuotationModalOpen}
        onClose={() => {
          setIsRedoQuotationModalOpen(false);
        }}
        onConfirm={() => {
          setIsRedoQuotationModalOpen(false);
          setIsEditingQuotationModalOpen(true);
        }}
        quotation={selectedQuotation}
      />
      <CreateQuotation
        isOpen={isCreateQuotationModalOpen}
        onClose={() => {
          setIsCreateQuotationModalOpen(false);
          setSelectedQuotation(null);
        }}
        defaultQuotation={
          isEditingQuotationModalOpen ? selectedQuotation : null
        }
        title={isEditingQuotationModalOpen ? 'Editar Cotação' : 'Criar Cotação'}
      />
      <EditQuotation
        isOpen={isEditingQuotationModalOpen}
        onClose={() => {
          setSelectedQuotation(null);
          setIsEditingQuotationModalOpen(false);
        }}
        onFinish={async () => {
          await deleteQuotationController(
            selectedQuotation?.id_quotation ?? '',
          );
        }}
        defaultQuotation={selectedQuotation}
        title="Editar Cotação"
      />
      <ViewCreatedQuotation
        isOpen={isViewQuotationModalOpen}
        onClose={() => {
          setIsViewQuotationModalOpen(false);
          setSelectedQuotation(null);
          setSearchedQuotation(null);
        }}
        quotation={searchedQuotation}
      />
      <FinishModal
        isLoading={isChangingQuotationState}
        isOpen={isChangingQuotationModalOpen}
        onClose={() => {
          handleFinishModal('close');
          setSelectedQuotation(null);
        }}
        onFinish={async () => {
          await changeQuotationState({
            id: selectedQuotation?.id_quotation ?? '',
            action: 'finish',
          });
          handleFinishModal('close');
          setSelectedQuotation(null);
        }}
        quotation={selectedQuotation}
      />

      <div className={styles.heading}>
        <div className={styles.describe}>
          <img src={createdQuotationsIcon} alt="cotações criadas" />
          <p>Cotações aguardando resposta</p>
        </div>
        <div className={styles.actions}>
          <FilterComponent
            handleDateSearch={handleDateSearch}
            handleSearch={handleSearch}
            handleFilterChange={(filter: string) =>
              setFilterType(filter as QuotationFilterOptions)
            }
            defaultValue="code"
            variant={
              filterType === 'code' || filterType === 'plate'
                ? 'searchbar'
                : 'date'
            }
            upperCase={filterType === 'plate'}
            onlyNumber={filterType === 'code'}
            options={[
              {
                label: 'Número',
                value: 'code',
              },
              {
                label: 'Placa',
                value: 'plate',
              },
              {
                label: 'Data',
                value: 'date',
              },
            ]}
          />

          <Button
            handleClick={() => setIsCreateQuotationModalOpen(true)}
            style={{
              whiteSpace: 'nowrap',
              padding: '0.875rem 0.625rem',
              borderRadius: '10px',
              backgroundColor: 'var(--primary)',
            }}
          >
            {!sizeMobile ? <PlusCircle size={32} /> : <PlusSquare size={32} />}
            Nova Cotação
          </Button>
        </div>
      </div>
      <ListTable.Root
        isLoading={isLoading}
        isEmpty={!isLoading && createdQuotations.length === 0}
        notFound={
          filterValue.trim().length > 0 && searchedQuotations.length === 0
        }
        message="Nenhuma cotação encontrada"
      >
        <div className={styles.container}>
          <ListTable.Head
            style={{
              gridTemplateColumns: !sizeMobile
                ? '1fr 2.5fr 1fr 1fr'
                : '1fr 2.5fr 1fr',
            }}
          >
            <ListTable.HeadItem>
              {sizeMobile ? 'N° de cotação' : 'Número de cotação'}
            </ListTable.HeadItem>
            {/* {!sizeMobile && <ListTable.HeadItem>Expiração</ListTable.HeadItem>} */}
            <ListTable.HeadItem>Data de Criação</ListTable.HeadItem>
            <ListTable.HeadItem>Placa</ListTable.HeadItem>
          </ListTable.Head>

          <ListTable.Body onScroll={() => loadMoreData(page)}>
            {/* if user has searched something it shows the response */}
            {(filterValue.trim().length > 0
              ? searchedQuotations
              : createdQuotations
            ).map(quotation => (
              <CreatedQuotesRow
                key={quotation.id_quotation}
                quotation={quotation}
                handleView={() => {
                  setIsViewQuotationModalOpen(true);
                }}
                handleFinish={() => handleFinishModal('open')}
                handleEdit={() => setIsRedoQuotationModalOpen(true)}
                onRowClick={() => handleRowClick(quotation)}
                isSelected={
                  !!selectedQuotation &&
                  selectedQuotation.id_quotation === quotation.id_quotation
                }
                onCountDownClick={() => {
                  setSelectedQuotation(quotation);
                  setIsIncreaseHoursModalOpen(true);
                }}
                handleAddCompany={() => setIsAddCompanyModalOpen(true)}
              />
            ))}
          </ListTable.Body>
        </div>
      </ListTable.Root>
    </>
  );
};
