import { Controller, useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { Modal } from '../../../../../../../shared/Modal';
import { Textarea } from '../../../../../../../shared/Textarea';
import { Input } from '../../../../../../../shared/Input';
import styles from './view-approved-quotation.module.css';
import { Separator } from '../../../../../../../shared/Separator';
import { AnswerQuotation } from '../../../../../../../@types/interface';
import { useResponsive } from '../../../../../../../hooks/useResponsive';
import { ViewItemQuotationList } from '../../ViewItemQuotationList';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  quotationAnswer: AnswerQuotation | null;
}

interface Quote {
  description: string;
  reference: string;
  quantity: string;
  value: string;
  availableQuantity: string;
}

export interface FormValues {
  plate?: string;
  vehicle?: string;
  chassi?: string;
  year?: string;
  model?: string;
  quotes: Quote[];
  observation?: string;
  paymentMethod: string;
}

export const ViewFinishedQuotation = ({
  isOpen,
  onClose,
  quotationAnswer,
}: Props) => {
  const { control, reset } = useForm<FormValues>({
    defaultValues: {
      chassi: '',
      model: '',
      plate: '',
      vehicle: '',
      year: '',
      quotes: [
        {
          description: '',
          quantity: '',
          reference: '',
          availableQuantity: '',
          value: '',
        },
      ],
      observation: '',
      paymentMethod: '',
    },
  });
  const { sizeMobile } = useResponsive();

  useEffect(() => {
    if (quotationAnswer) {
      reset({
        chassi: quotationAnswer.quotation?.vehicle?.chassi ?? '',
        model: quotationAnswer.quotation?.vehicle?.model ?? '',
        plate: quotationAnswer.quotation?.vehicle?.license_plate ?? '',
        vehicle: quotationAnswer.quotation?.vehicle?.name ?? '',
        year: quotationAnswer.quotation?.vehicle?.year ?? '',
        quotes:
          quotationAnswer?.quotation?.items_quotation.map(item => ({
            description: item?.description ?? '',
            quantity: item?.quantity ?? '',
            reference: item?.reference ?? '',
            availableQuantity: '',
            value: '',
          })) ?? [],
        observation: quotationAnswer?.quotation?.comment ?? '',
        paymentMethod:
          quotationAnswer?.quotation?.type_payment ?? 'Não informado',
      });
    }
  }, [quotationAnswer, reset]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={`Visualizar Cotação - N° ${
        quotationAnswer?.quotation?.QUOTATION || ''
      }`}
      size={sizeMobile ? 'full' : 'large'}
    >
      <div className={styles['vehicle-info']}>
        <div className={styles.row}>
          <Controller
            name="plate"
            control={control}
            render={({ field: { name, onChange, value } }) => (
              <Input
                name={name}
                label="Placa:"
                value={value}
                handleChange={onChange}
                disabled
                style={{ fontWeight: '600', color: '#000000' }}
              />
            )}
          />
          <Controller
            name="vehicle"
            control={control}
            render={({ field: { name, onChange, value } }) => (
              <Input
                name={name}
                label="Veículo:"
                value={value}
                handleChange={onChange}
                disabled
                style={{ fontWeight: '600', color: '#000000' }}
              />
            )}
          />
          <Controller
            name="chassi"
            control={control}
            render={({ field: { name, onChange, value } }) => (
              <Input
                name={name}
                label="Chassi:"
                value={value}
                handleChange={onChange}
                disabled
                style={{ fontWeight: '600', color: '#000000' }}
              />
            )}
          />
        </div>
        <div className={styles.row}>
          <Controller
            name="year"
            control={control}
            render={({ field: { name, onChange, value } }) => (
              <Input
                name={name}
                label="Ano:"
                value={value}
                handleChange={onChange}
                disabled
                style={{ fontWeight: '600', color: '#000000' }}
              />
            )}
          />
          <Controller
            name="model"
            control={control}
            render={({ field: { name, onChange, value } }) => (
              <Input
                name={name}
                label="Modelo:"
                value={value}
                handleChange={onChange}
                disabled
                style={{ fontWeight: '600', color: '#000000' }}
              />
            )}
          />
        </div>
        <Controller
          name="paymentMethod"
          control={control}
          render={({ field: { name, onChange, value } }) => (
            <Input
              name={name}
              label="Forma de Pagamento:"
              value={value}
              handleChange={onChange}
              disabled
              style={{ fontWeight: '600', color: '#000000' }}
            />
          )}
        />
        <div className={styles.observations}>
          <Controller
            name="observation"
            control={control}
            render={({ field: { name, onChange, value } }) => (
              <Textarea
                name={name}
                handleChange={onChange}
                value={value}
                label="Observações:"
                disabled
                style={{
                  height: '5rem',
                  fontWeight: '500',
                  color: '#000000',
                }}
              />
            )}
          />
        </div>
      </div>
      <ViewItemQuotationList quotationAnswer={quotationAnswer} />
      <Separator
        theme="square"
        style={{
          margin: '2rem 0',
        }}
      />
      <div className={styles.observations}>
        <Textarea
          name="comment"
          handleChange={() => console.log()}
          label="Comentário do fornecedor:"
          value={quotationAnswer?.comment ?? ''}
          disabled
          style={{
            height: '5rem',
            fontWeight: '500',
            color: '#000000',
          }}
        />
        <Textarea
          name="rebuttal"
          handleChange={() => console.log()}
          label="Resposta da Oficina:"
          value={quotationAnswer?.rebuttal ?? ''}
          disabled
          style={{
            height: '5rem',
            fontWeight: '500',
            color: '#000000',
          }}
        />
      </div>
    </Modal>
  );
};
