import React, { useState } from 'react';
import { Minus, Plus, Timer } from 'phosphor-react';
import { Modal } from '../../../../../../../shared/Modal';
import { Button } from '../../../../../../../shared/Button';
import { Quotation } from '../../../../../../../@types/interface';
import styles from './increase-quotation-hours-modal.module.css';
import { useResponsive } from '../../../../../../../hooks/useResponsive';

interface Props {
  isOpen: boolean;
  isIncrementingHours: boolean;
  quotation: Quotation | null;
  onClose: () => void;
  onSave: (quotation: Quotation, hours: number) => void;
}

export const IncreaseQuotationHoursModal = ({
  isOpen,
  quotation,
  onClose,
  onSave,
  isIncrementingHours,
}: Props) => {
  const { sizeMobile } = useResponsive();

  const [increaseHours, setIncreaseHours] = useState(0);

  return (
    <Modal
      isOpen={isOpen}
      title="Tempo de Expiração"
      onClose={onClose}
      isLoading={isIncrementingHours}
      footer={
        <Button
          handleClick={() => {
            if (quotation) onSave(quotation, increaseHours);
          }}
          variant="register"
        >
          Salvar
        </Button>
      }
    >
      <div className={styles.timer}>
        {!sizeMobile && <Timer size={48} color="var(--error)" weight="bold" />}
        <div className={styles.counter}>
          <Button
            handleClick={() => {
              setIncreaseHours(previous => (previous > 0 ? previous - 1 : 0));
            }}
          >
            <Minus size={32} />
          </Button>

          <span>{increaseHours.toString().padStart(2, '0')}:00</span>
          <Button
            handleClick={() => {
              setIncreaseHours(previous => previous + 1);
            }}
          >
            <Plus size={32} />
          </Button>
        </div>
      </div>
    </Modal>
  );
};
