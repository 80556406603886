/* eslint-disable jsx-a11y/label-has-associated-control */
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Eye, EyeSlash } from 'phosphor-react';
import styles from './login.module.css';
import mainFigure from '../../../../../assets/img/LandingPage/mechanical.svg';
import backgroundPattern from '../../../../../assets/img/LandingPage/backgroundPatternLogin.svg';
import { visitorAuthenticate } from './model';
import { authenticateController, schema } from './controller';
import { LoginProps, LoginResponse } from './interface';
import { isAuthenticated } from '../../../../../services/api/auth';
import { useAuth } from '../../../../../hooks/useAuth';
import { getCurrentVersion } from '../../../../../shared/RefreshModal/model';
import { COLORS } from '../../../../../styles/colors';
import { Container } from '../../Components/Container/Container';
import { ForgetModalLandingPage } from './components/ForgetModal/index';
import { Button } from '../../../../../shared/Button';

export const Login = () => {
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<LoginProps>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      password: '',
    },
  });
  const { setUser, setWorkshop } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [isWrong, setIsWrong] = useState(false);
  const [isLoadingVisitor, setIsLoadingVisitor] = useState(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showForgetModal, setShowForgetModal] = useState<boolean>(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const isVisitor = queryParams.get('visitor');

  useEffect(() => {
    if (isVisitor === 'true') {
      onVisitorSubmit();
    }

    if (isAuthenticated()) {
      navigate('/home');
    }
    const localUser = localStorage.getItem('@AutoCenter: user') || '';
    if (localUser) setValue('email', JSON.parse(localUser)?.email || '');
  }, []);

  const onSubmit = async (data: LoginProps) => {
    setIsLoading(true);
    const res = await authenticateController({
      email: data.email.trim(),
      password: data.password,
    });
    setIsLoading(false);
    if (res) {
      setUser(res.user);
      setWorkshop(res.user.agents[0].company);
      await handleLocalStorage(res);
    } else {
      setIsWrong(true);
      setTimeout(() => setIsWrong(false), 1000);
    }
  };

  const onVisitorSubmit = async () => {
    setIsLoadingVisitor(true);

    const res = await visitorAuthenticate();
    if (res) {
      setUser(res.user);
      setWorkshop(res.user.agents[0].company);
      await handleLocalStorage(res);
    }
    setIsLoadingVisitor(false);
  };

  const fetchVersion = async () => {
    const res = await getCurrentVersion();
    if (res) localStorage.setItem('@Autocenter: version', res.version);
  };

  const handleLocalStorage = async (response: LoginResponse) => {
    localStorage.setItem('@AutoCenter: user', JSON.stringify(response.user));
    localStorage.setItem('@AutoCenter: accessToken', response.accessToken);
    localStorage.setItem('@AutoCenter: refreshToken', response.refreshToken);
    localStorage.setItem('reload', 'true');

    await fetchVersion();

    navigate('/home');
  };

  return (
    <Container>
      <ForgetModalLandingPage
        isOpen={showForgetModal}
        setIsOpen={setShowForgetModal}
      />
      <main className={styles.main}>
        <header className={styles.headerTop}>
          <strong>Fazer Login</strong>
          <p>Bem-vindo de volta 👋</p>
        </header>
        <div className={styles.wrapper}>
          <img src={mainFigure} alt="mecanico" />
          <div>
            <header className={styles.header}>
              <strong>Fazer Login</strong>
              <p>Bem-vindo de volta 👋</p>
            </header>
            <form className={styles.inputArea}>
              <Controller
                control={control}
                name="email"
                render={({ field: { onChange, value } }) => (
                  <>
                    <label htmlFor="name">CPF</label>
                    <input
                      type="text"
                      value={value}
                      onChange={onChange}
                      id="name"
                      placeholder="724.792.540-03"
                    />
                    {errors && errors?.email && (
                      <span
                        style={{
                          color: COLORS.RED,
                          fontSize: '0.8rem',
                        }}
                      >
                        {errors?.email.message}
                      </span>
                    )}
                  </>
                )}
              />

              <Controller
                control={control}
                name="password"
                render={({ field: { onChange, value } }) => (
                  <>
                    <label htmlFor="password">Senha</label>
                    <div className={styles['input-icon-container']}>
                      <input
                        type={showPassword ? 'text' : 'password'}
                        value={value}
                        onChange={onChange}
                        id="password"
                        placeholder="senha de 6 dígitos"
                      />
                      <button
                        type="button"
                        className={styles.icon}
                        onClick={() => setShowPassword(!showPassword)}
                        style={{ display: value ? 'block' : 'none' }}
                      >
                        {showPassword ? (
                          <Eye size="1.8rem" color={COLORS.BLACK} />
                        ) : (
                          <EyeSlash size="1.8rem" color={COLORS.BLACK} />
                        )}
                      </button>
                    </div>
                    {errors && errors?.password && (
                      <span
                        style={{
                          color: COLORS.RED,
                          fontSize: '0.8rem',
                        }}
                      >
                        {errors?.password?.message}
                      </span>
                    )}
                  </>
                )}
              />

              <div className={styles.row}>
                <label htmlFor="keepLogged">
                  <input
                    className={styles.checkbox}
                    type="checkbox"
                    id="keepLogged"
                  />
                  <p className={styles.checkboxText}>Manter Logado</p>
                </label>
                <Button
                  handleClick={() => setShowForgetModal(true)}
                  variant="unstyled"
                >
                  <strong
                    style={{
                      color: 'var(--primary)',
                    }}
                  >
                    Esqueci minha senha
                  </strong>
                </Button>
              </div>

              <button
                type="button"
                className={`${styles['login-btn']} ${
                  isWrong ? styles.wrong : ''
                }`}
                onClick={handleSubmit(onSubmit)}
              >
                {!isLoading ? 'LOGIN' : <div className={styles.loader} />}
              </button>
            </form>
            <div className={styles.cadastro_recuperacao}>
              <div className={styles.mobile}>
                <Button
                  handleClick={() => setShowForgetModal(true)}
                  variant="unstyled"
                >
                  <strong
                    style={{
                      color: 'var(--primary)',
                    }}
                  >
                    Esqueci minha senha
                  </strong>
                </Button>
              </div>
            </div>
          </div>
        </div>

        <img
          className={styles.backgroundPatterLogin}
          src={backgroundPattern}
          alt="marca"
        />
      </main>
    </Container>
  );
};

export default Login;
