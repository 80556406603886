import React, { ReactNode } from 'react';
import { Button } from '../../../Button';

interface Props {
  handleNavigation: () => void;
  isSelected?: boolean;
  children: ReactNode;
}

export const NavbarItem = ({
  children,
  handleNavigation,
  isSelected,
}: Props) => {
  return (
    <Button
      handleClick={handleNavigation}
      variant="navbar"
      isSelected={isSelected}
      >
      {children}
    </Button>
  );
};
