import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  BudgetProps,
  ConversationMessagesProps,
  ConversationProps,
  ContactProps,
} from './interfaces';

interface ConversationState {
  conversations: ConversationProps[];
  newMessages: ConversationProps[];
  currentContact: ContactProps | null;
  budget: BudgetProps;
  conversationsMessages: ConversationMessagesProps[];
}
const initialState: ConversationState = {
  conversations: [],
  newMessages: [],
  currentContact: null,
  budget: {
    name: '',
    document: '',
    phone: '',
    license_plate: '',
    vehicle: '',
    validity: '',
    defect: '',
    comment: '',
    services: [],
    promotional_services: [],
    discount: '0',
    chassi: '',
    status: '',
  },
  conversationsMessages: [],
};

export const conversationSlice = createSlice({
  name: 'conversation',
  initialState,
  reducers: {
    setConversations: (state, action: PayloadAction<ConversationProps[]>) => {
      if (action.payload.length > 0) {
        state.conversations = action.payload;
        // state.newMessages = [];
      }
    },
    setNewMessages: (state, action: PayloadAction<ConversationProps[]>) => {
      const oldConversations = state.conversations;
      const newConversations = action.payload;
      const newConversationsToUpdate: ConversationProps[] = [];
      // verifica se o estado esta vazio
      if (oldConversations.length === 0) {
        state.conversations = [...newConversations];
        return;
      }
      // verifica se houve alguma mudança
      if (
        JSON.stringify(oldConversations) !== JSON.stringify(newConversations)
      ) {
        newConversations.forEach(newConversation => {
          const newConversationId =
            newConversation.last_non_activity_message.id;

          // busca pelo id da ultima mensagem para ver se é nova
          const oldConversation = oldConversations.find(
            conversation =>
              conversation.last_non_activity_message.id === newConversationId,
          );
          if (
            newConversationId &&
            !oldConversation &&
            newConversation.last_non_activity_message.message_type !== 1
          ) {
            newConversationsToUpdate.push(newConversation);
          }
        });
        state.newMessages = newConversationsToUpdate;
      }
    },
    resetNewMessages: state => {
      state.newMessages = [];
    },
    saveConversationsLocally: state => {
      const conversationString = JSON.stringify(state.conversations);
      localStorage.setItem('conversations', conversationString);
    },
    deleteNewMessage: (state, action: PayloadAction<string>) => {
      const id = parseInt(action.payload, 10);
      const filteredNewMessages = state.newMessages.filter(
        message => message.id !== id,
      );
      state.newMessages = filteredNewMessages;
    },
    setCurrentContact: (state, action: PayloadAction<ContactProps | null>) => {
      state.currentContact = action.payload;
    },
    setBudget: (state, action: PayloadAction<BudgetProps>) => {
      state.budget = action.payload;
    },
    addConversationMessages: (
      state,
      action: PayloadAction<ConversationMessagesProps>,
    ) => {
      const newConversationMessages = action.payload;
      const existingConversationIndex = state.conversationsMessages.findIndex(
        conversation => conversation.id === newConversationMessages.id,
      );
      if (existingConversationIndex === -1) {
        // A conversa ainda não existe, adiciona a nova conversa
        state.conversationsMessages = [
          ...state.conversationsMessages,
          newConversationMessages,
        ];
      } else {
        // A conversa já existe, adiciona apenas as novas mensagens
        const existingConversation =
          state.conversationsMessages[existingConversationIndex];
        const existingMessageIds = existingConversation.messages.map(
          message => message.message_id,
        );
        const newMessages = newConversationMessages.messages.filter(
          message => !existingMessageIds.includes(message.message_id),
        );
        if (newMessages.length > 0) {
          state.conversationsMessages[existingConversationIndex].messages = [
            ...existingConversation.messages,
            ...newMessages,
          ];
        }
      }
    },
  },
});

export const {
  setConversations,
  setNewMessages,
  resetNewMessages,
  saveConversationsLocally,
  deleteNewMessage,
  setCurrentContact,
  setBudget,
  addConversationMessages,
} = conversationSlice.actions;
export const conversationReducer = conversationSlice.reducer;
