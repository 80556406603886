import { useMemo, useState } from 'react';
import Lottie from 'lottie-react';
import { Modal } from '../../../../../../../shared/Modal';
import { StepsController } from '../../../../../../../shared/StepsController';
import { Button } from '../../../../../../../shared/Button';
import { Items } from './steps/Items';
import { Companies } from './steps/Companies';
import styles from './create-quotation.module.css';
import creatingAnimation from '../../../../../../../assets/roda.json';
import errorAnimation from '../../../../../../../assets/errorLottie.json';
import { useCreateQuotation } from './useCreateQuotation';
import { WorkshopList, useSendQuotation } from './useSendQuotation';
import { useQuotationForm } from './useQuotationForm';
import { AlertMessage } from '../../../../../../../shared/AlertMessage';
import { Quotation } from '../../../../../../../@types/interface';
import { useResponsive } from '../../../../../../../hooks/useResponsive';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  defaultQuotation?: Quotation | null;
  title: string;
}

export const CreateQuotation = ({
  isOpen,
  onClose,
  defaultQuotation = null,
  title,
}: Props) => {
  const [step, setStep] = useState<
    'items' | 'companies' | 'creating' | 'sending' | 'success' | 'error'
  >('items');
  const [alertMessage, setAlertMessage] = useState('');
  const { createQuotation, isCreating } = useCreateQuotation();
  const { sendQuotation, isSending } = useSendQuotation();
  const {
    control,
    getValues,
    imagesList,
    resetFormValues,
    handleRemoveImage,
    handleImageChange,
    handleAddRow,
    handleVehicleFields,
    quotes,
    errors,
    remove,
    trigger,
    paymentTypes,
  } = useQuotationForm({ defaultQuotation });
  const { sizeMobile } = useResponsive();
  const [workshopList, setWorkshopList] = useState<WorkshopList[]>([]);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const handleStep = () => {
    if (step === 'items') {
      return (
        <Items
          control={control}
          handleAddRow={handleAddRow}
          handleImageChange={handleImageChange}
          handleRemoveImage={handleRemoveImage}
          quotes={quotes}
          remove={remove}
          imagesList={imagesList}
          handleVehicleFields={handleVehicleFields}
          paymentTypes={paymentTypes}
        />
      );
    }
    if (step === 'companies') {
      return (
        <Companies
          setWorkshopList={setWorkshopList}
          workshopList={workshopList}
        />
      );
    }
    if (step === 'creating') {
      return (
        <div style={{ width: '300px', height: '300px', margin: 'auto' }}>
          <Lottie animationData={creatingAnimation} />
        </div>
      );
    }
    if (step === 'sending') {
      return (
        <div style={{ width: '300px', height: '300px', margin: 'auto' }}>
          <Lottie animationData={creatingAnimation} />
        </div>
      );
    }
    if (step === 'success') {
      return (
        <div style={{ width: '300px', height: '300px', margin: 'auto' }}>
          <Lottie animationData={creatingAnimation} loop={false} />
        </div>
      );
    }
    if (step === 'error') {
      return (
        <div style={{ width: '300px', height: '300px', margin: 'auto' }}>
          <Lottie animationData={errorAnimation} />
        </div>
      );
    }
  };

  const handleCreateQuotation = async () => {
    const {
      quotes: items,
      vehicleId,
      comment,
      // hours,
      paymentMethod,
    } = getValues();
    const res = await createQuotation({
      items,
      vehicleId,
      comment: comment ?? '',
      imagesList,
      // hours,
      type_payment: paymentMethod,
    });
    if (res) {
      return res;
    }
    setStep('error');
  };

  const handleSendQuotation = async (quotationId: string) => {
    const res = await sendQuotation({
      quotationId,
      workshopList,
    });
    if (res) {
      setStep('success');
      handleClose();
    } else {
      setStep('error');
    }
  };

  const onSubmit = async () => {
    setStep('creating');
    const res = await handleCreateQuotation();
    if (res) {
      setStep('sending');
      await handleSendQuotation(res.id_quotation);
    }
  };

  const handleClose = () => {
    setStep('items');
    resetFormValues();
    onClose();
  };

  const handleModalTitle = useMemo(() => {
    if (step === 'items' || step === 'companies') return title;
    if (step === 'sending') return 'Enviando Cotação';
    if (step === 'creating') return 'Criando Cotação';
    if (step === 'success') return 'Cotação Criada';
    return 'Falha ao criar cotação';
  }, [step, title]);

  const changeStep = async () => {
    const isValid = await trigger();
    if (isValid && !isEdit) {
      setStep('companies');
    } else if (errors.quotes) {
      setAlertMessage(
        'Verifique os itens da cotação, ela não pode ser uma cotação vazia de itens!',
      );
    } else if (isEdit) {
      setAlertMessage('Salve suas alterações');
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      title={handleModalTitle}
      size={sizeMobile ? 'full' : 'large'}
      footer={
        step === 'items' || step === 'companies' ? (
          <div className={styles.wrapButtonFooter}>
            <Button
              variant="register"
              style={{
                padding: '.5rem 2.5rem',
              }}
              handleClick={() => {
                if (step === 'items') {
                  changeStep();
                } else {
                  setIsConfirmModalOpen(true);
                }
              }}
            >
              {step === 'items' ? 'Próximo' : 'Enviar'}
            </Button>
          </div>
        ) : null
      }
    >
      {(step === 'items' || step === 'companies') && (
        <StepsController.Root>
          <StepsController.Item
            label="1. Itens"
            selected={step === 'items'}
            onClick={() => setStep('items')}
          />
          <StepsController.Item
            label="2. Fornecedores"
            selected={step === 'companies'}
            onClick={() => changeStep()}
          />
        </StepsController.Root>
      )}
      <AlertMessage.Root
        isOpen={alertMessage.length !== 0}
        onClose={() => setAlertMessage('')}
      >
        <AlertMessage.Body>{alertMessage}</AlertMessage.Body>
      </AlertMessage.Root>

      <AlertMessage.Root
        isOpen={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        title="Confirmar Envio"
        footer={
          <AlertMessage.Footer>
            <AlertMessage.CancelButton
              onClick={() => setIsConfirmModalOpen(false)}
            />
            <AlertMessage.ConfirmButton
              onClick={() => {
                setIsConfirmModalOpen(false);
                onSubmit();
              }}
            >
              Enviar Cotação
            </AlertMessage.ConfirmButton>
          </AlertMessage.Footer>
        }
      >
        <AlertMessage.Body>
          Tem certeza que deseja enviar a cotação?
        </AlertMessage.Body>
      </AlertMessage.Root>

      {handleStep()}
    </Modal>
  );
};
