import React from 'react';
import { Button } from '../../../Button';

interface Props {
  children?: React.ReactNode;
  onClick: () => void;
}

export const ConfirmButton = ({ children = 'Confirmar', onClick }: Props) => {
  return (
    <Button
      handleClick={onClick}
      style={{
        fontSize: '1.5rem',
        padding: '.25rem .75rem',
        marginRight: '0.25rem,',
        marginLeft: '0.25rem',
        textAlign: 'center',
      }}
      variant="register"
    >
      {children}
    </Button>
  );
};
