import { useEffect, useState } from 'react';
import { Quotation } from '../../../../../../../@types/interface';
import { QuotationFilterOptions } from '../../../interface';

interface Props {
  fetchQuotationById: (id: string) => void;
  fetchQuotationsByFilter: (
    filterValue: string,
    filterType: QuotationFilterOptions,
  ) => void;
}

export const useCreatedQuotation = ({
  fetchQuotationById,
  fetchQuotationsByFilter,
}: Props) => {
  const [selectedQuotation, setSelectedQuotation] = useState<Quotation | null>(
    null,
  );
  const [isViewQuotationModalOpen, setIsViewQuotationModalOpen] =
    useState(false);
  const [isCreateQuotationModalOpen, setIsCreateQuotationModalOpen] =
    useState(false);
  const [isEditingQuotationModalOpen, setIsEditingQuotationModalOpen] =
    useState(false);

  const [filterType, setFilterType] = useState<QuotationFilterOptions>('code');
  const [filterValue, setFilterValue] = useState('');
  const [isIncreaseHoursModalOpen, setIsIncreaseHoursModalOpen] =
    useState(false);

  const [isRedoQuotationModalOpen, setIsRedoQuotationModalOpen] =
    useState(false);

  const handleDateSearch = (period: string[]) => {
    // clear search state for invalid period
    if (period.length !== 2) {
      setFilterValue('');
    } else {
      const [initialDate, finalDate] = period;
      if (initialDate.trim().length > 0 && finalDate.trim().length > 0) {
        setFilterValue(`["${initialDate}", "${finalDate}"]`);
      }
    }
  };
  const handleSearch = (query: string) => {
    setFilterValue(query);
  };

  useEffect(() => {
    if (selectedQuotation) {
      fetchQuotationById(selectedQuotation.id_quotation);
    }
  }, [fetchQuotationById, selectedQuotation]);

  useEffect(() => {
    if (filterValue.trim().length > 0) {
      fetchQuotationsByFilter(filterValue, filterType);
    }
  }, [fetchQuotationsByFilter, filterType, filterValue]);

  const handleRowClick = (quotation: Quotation) => {
    if (
      !selectedQuotation ||
      selectedQuotation.id_quotation !== quotation.id_quotation
    ) {
      setSelectedQuotation(quotation);
    } else {
      setSelectedQuotation(null);
    }
  };

  return {
    handleRowClick,
    isCreateQuotationModalOpen,
    setIsCreateQuotationModalOpen,
    setSelectedQuotation,
    isEditingQuotationModalOpen,
    setIsEditingQuotationModalOpen,
    selectedQuotation,
    isViewQuotationModalOpen,
    setIsViewQuotationModalOpen,
    filterType,
    setFilterType,
    handleDateSearch,
    handleSearch,
    filterValue,
    isRedoQuotationModalOpen,
    setIsRedoQuotationModalOpen,
    isIncreaseHoursModalOpen,
    setIsIncreaseHoursModalOpen,
  };
};
