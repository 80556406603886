import * as yup from 'yup';
import { updateUserPassword } from './model';
import message from '../../../../utils/message';
import { UpdateUserPasswordInput } from './interfaces';

export const updateUserPasswordController = async (
  props: UpdateUserPasswordInput,
) => {
  try {
    const schema = yup.object({
      newPassword: yup.string().required('A senha é um campo obrigatório'),
      confirmPassword: yup
        .string()
        .required('A confirmação de senha é um campo obrigatório')
        .oneOf([yup.ref('newPassword'), null], 'As senhas não coincidem'),
    });
    await schema.validate(props);
    const res = await updateUserPassword({
      newPassword: props.newPassword,
      phone: props.phone,
    });
    message('Senha atualizada com sucesso', 'success');
    return res;
  } catch (error) {
    if (error instanceof yup.ValidationError) {
      message(error.errors[0], 'warning');
    } else {
      message(error, 'error');
    }
  }
};
