import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AnswerQuotation } from '../../@types/interface';

interface QuotationState {
  notAnsweredQuotationList: AnswerQuotation[];
  notApprovedQuotationList: AnswerQuotation[];
  finishedQuotationList: AnswerQuotation[];
  rejectedQuotationList: AnswerQuotation[];
}

const initialState: QuotationState = {
  notAnsweredQuotationList: [],
  notApprovedQuotationList: [],
  finishedQuotationList: [],
  rejectedQuotationList: [],
};

export const answerQuotationSlice = createSlice({
  name: 'answer-quotation',
  initialState,
  reducers: {
    addNotAnsweredQuotationList: (
      state,
      action: PayloadAction<AnswerQuotation[]>,
    ) => {
      const newNotAnsweredQuotationList = action.payload.filter(
        quotation =>
          !state.notAnsweredQuotationList.some(
            previousQuotation =>
              previousQuotation.id_answer_quotation ===
              quotation.id_answer_quotation,
          ),
      );
      state.notAnsweredQuotationList = [
        ...state.notAnsweredQuotationList,
        ...newNotAnsweredQuotationList,
      ];
    },
    addNotConfirmedQuotations: (
      state,
      action: PayloadAction<AnswerQuotation[]>,
    ) => {
      const newNotApprovedQuotationList = action.payload.filter(
        quotation =>
          !state.notApprovedQuotationList.some(
            previousQuotation =>
              previousQuotation.id_answer_quotation ===
              quotation.id_answer_quotation,
          ),
      );
      state.notApprovedQuotationList = [
        ...state.notApprovedQuotationList,
        ...newNotApprovedQuotationList,
      ];
    },
    addFinishedQuotations: (
      state,
      action: PayloadAction<AnswerQuotation[]>,
    ) => {
      const newFinishedQuotationList = action.payload.filter(
        quotation =>
          !state.finishedQuotationList.some(
            previousQuotation =>
              previousQuotation.id_answer_quotation ===
              quotation.id_answer_quotation,
          ),
      );
      state.finishedQuotationList = [
        ...state.finishedQuotationList,
        ...newFinishedQuotationList,
      ];
    },
    setNotAnsweredQuotationList: (
      state,
      action: PayloadAction<AnswerQuotation[]>,
    ) => {
      state.notAnsweredQuotationList = action.payload;
    },
    setNotConfirmedQuotations: (
      state,
      action: PayloadAction<AnswerQuotation[]>,
    ) => {
      state.notApprovedQuotationList = action.payload;
    },
    setFinishedQuotations: (
      state,
      action: PayloadAction<AnswerQuotation[]>,
    ) => {
      state.finishedQuotationList = action.payload;
    },
    setRejectedQuotations: (
      state,
      action: PayloadAction<AnswerQuotation[]>,
    ) => {
      state.rejectedQuotationList = action.payload;
    },
    removeNotConfirmedQuotation: (state, action: PayloadAction<string>) => {
      state.notApprovedQuotationList = state.notApprovedQuotationList.filter(
        quotation => quotation.id_answer_quotation !== action.payload,
      );
    },
    updateNotAnsweredQuotation: (
      state,
      action: PayloadAction<AnswerQuotation>,
    ) => {
      const updatedNotAnsweredQuotationList =
        state.notAnsweredQuotationList.map(answer => {
          if (answer.id_answer_quotation === action.payload.id_answer_quotation)
            return action.payload;
          return answer;
        });
      state.notAnsweredQuotationList = updatedNotAnsweredQuotationList;
      // order by date
      // .sort(
      //   (a, b) =>
      //     new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
      // )
      // order by visualization
      // .sort((a, b) => {
      //   if (a.visualized && !b.visualized) return 1;
      //   if (!a.visualized && b.visualized) return -1;
      //   return 0;
      // });
    },
    updateNotApprovedQuotation: (
      state,
      action: PayloadAction<AnswerQuotation>,
    ) => {
      const updatedNotApprovedQuotationList =
        state.notApprovedQuotationList.map(answer => {
          if (answer.id_answer_quotation === action.payload.id_answer_quotation)
            return action.payload;
          return answer;
        });
      state.notApprovedQuotationList = updatedNotApprovedQuotationList;
      // order by date
      // .sort(
      //   (a, b) =>
      //     new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
      // )
      // order by visualization
      // .sort((a, b) => {
      //   if (a.visualized && !b.visualized) return 1;
      //   if (!a.visualized && b.visualized) return -1;
      //   return 0;
      // });
    },
  },
});

export const {
  addNotConfirmedQuotations,
  addFinishedQuotations,
  addNotAnsweredQuotationList,
  setNotAnsweredQuotationList,
  setNotConfirmedQuotations,
  setFinishedQuotations,
  removeNotConfirmedQuotation,
  updateNotAnsweredQuotation,
  updateNotApprovedQuotation,
  setRejectedQuotations,
} = answerQuotationSlice.actions;

export const answerQuotationReducer = answerQuotationSlice.reducer;
