import { Body } from './components/Body';
import { Header } from './components/Header';
import { Row } from './components/Row';
import { Root } from './components/Root';

export const ProductTable = {
  Body,
  Header,
  Row,
  Root,
};
