import { useState } from 'react';
import { Banner } from '../../@types/interface';
import styles from './horizontal-banner-list.module.css';
import imgPlaceholder from '../../assets/img/image-placeholder.svg';
import { BannerViewModal } from '../BannerViewModal';

type Props = {
  bannerList: Banner[];
  label?: string;
};

export const HorizontalBannerList = ({ bannerList, label = '' }: Props) => {
  const [selectedBanner, setSelectedBanner] = useState<Banner | null>(null);

  return (
    <>
      <BannerViewModal
        banner={selectedBanner}
        isOpen={!!selectedBanner}
        onClose={() => setSelectedBanner(null)}
      />
      <div className={styles.container}>
        {label && <span className={styles.label}>{label}</span>}
        <div className={styles.wrapper}>
          {bannerList.map((banner: Banner) => (
            <button
              type="button"
              onClick={() => {
                setSelectedBanner(banner);
              }}
            >
              <img
                src={banner?.img_url || imgPlaceholder}
                key={banner.id_banner}
                alt={banner.title || ''}
              />
            </button>
          ))}
        </div>
      </div>
    </>
  );
};
