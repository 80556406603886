import { CSSProperties } from 'react';
import styles from './separator.module.css';

interface Props {
  theme?: 'default' | 'square';
  style?: CSSProperties;
}

export const Separator = ({ theme = 'default', style }: Props) => {
  return (
    <div
      className={`${styles.separator} ${styles[theme]}`}
      style={{ ...style }}
    />
  );
};
