import { useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import { Input, Option } from '../../../../../../../../../shared/Input';
import { FormValues } from '../../useQuotationForm';
import { maskLicensePlate } from '../../../../../../../../../services/helpers/mask';
import { fetchVehicleDataByLicensePlateController } from '../../../../../../../../../services/dataFetchers/VehicleDataFetcher/VehicleDataFetcher';
import styles from './vehicle-info.module.css';
import { VehicleResponse } from '../../../../../../../../../services/dataFetchers/VehicleDataFetcher/model';
import { PaymentTypes } from '../../../../../interface';

interface Props {
  control: Control<FormValues, any>;
  handleVehicleFields: (vehicle: VehicleResponse) => void;
  paymentTypes: PaymentTypes[];
}

export const VehicleInfo = ({
  control,
  handleVehicleFields,
  paymentTypes,
}: Props) => {
  const [isLoadingVehicle, setIsLoadingVehicle] = useState(false);

  const handleVehicleSearch = async (plate: string) => {
    setIsLoadingVehicle(true);
    const res = await fetchVehicleDataByLicensePlateController(plate);
    if (res) {
      handleVehicleFields(res);
    }
    setIsLoadingVehicle(false);
  };

  const paymentTypeOptions: Option[] = [
    {
      name: 'Escolha uma opção',
      value: '',
    },
    ...paymentTypes.map(paymentType => ({
      name: paymentType,
      value: paymentType,
    })),
  ];
  return (
    <div className={styles['vehicle-info']}>
      <div className={styles.row}>
        <Controller
          name="plate"
          control={control}
          render={({ field: { name, onChange, value } }) => (
            <Input
              name={name}
              label="Placa:"
              value={maskLicensePlate(value || '')}
              isLoading={isLoadingVehicle}
              maxLength={7}
              handleChange={e => {
                const formattedPlate = maskLicensePlate(e.target.value);
                if (formattedPlate.length === 7) {
                  handleVehicleSearch(formattedPlate);
                }
                onChange(e);
              }}
            />
          )}
        />
        <Controller
          name="year"
          control={control}
          render={({ field: { name, onChange, value } }) => (
            <Input
              name={name}
              label="Ano:"
              value={value}
              handleChange={onChange}
              disabled
            />
          )}
        />
      </div>
      <div className={styles.row}>
        <Controller
          name="vehicle"
          control={control}
          render={({ field: { name, onChange, value } }) => (
            <Input
              name={name}
              label="Veículo:"
              value={value}
              handleChange={onChange}
              disabled
            />
          )}
        />
      </div>
      <div className={styles.row}>
        <Controller
          name="chassi"
          control={control}
          render={({ field: { name, onChange, value } }) => (
            <Input
              name={name}
              label="Chassi:"
              value={value}
              handleChange={onChange}
              disabled
            />
          )}
        />
        {/* <Controller
          name="hours"
          control={control}
          render={({ field: { name, onChange, value } }) => (
            <Input
              name={name}
              label="Tempo de resposta:"
              placeholder="8 horas"
              value={value}
              handleChange={e => {
                const newValue = e.target.value;
                if (/^\d*$/.test(newValue)) {
                  onChange(e);
                }
              }}
              pattern="\d*"
            />
          )}
        /> */}
        <Controller
          name="paymentMethod"
          control={control}
          render={({ field: { name, onChange, value } }) => (
            <Input
              name={name}
              label="Forma de Pagamento:"
              value={value}
              handleChange={onChange}
              type="select"
              options={paymentTypeOptions}
              style={{
                fontWeight: '500',
                fontSize: '1.125rem',
              }}
            />
          )}
        />
      </div>
    </div>
  );
};
