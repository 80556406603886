import React from 'react';
import { Modal } from '../../../Modal';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  children: React.ReactNode;
  footer?: React.ReactNode;
  isLoading?: boolean;
  container?: Element | DocumentFragment;
  disableScroll?: boolean;
}

export const Root = ({
  isOpen,
  onClose,
  title = 'atenção',
  children,
  footer,
  isLoading = false,
  container = document.body,
  disableScroll = false,
}: Props) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      disableScroll={disableScroll}
      footer={footer}
      isLoading={isLoading}
      container={container}
    >
      {children}
    </Modal>
  );
};
