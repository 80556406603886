/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { CSSProperties, useState } from 'react';
import styles from './row.module.css';
import { Checkbox } from '../../../Checkbox';

interface Props {
  children: React.ReactNode;
  hasCheckbox?: boolean;
  style?: CSSProperties;
  handleClick?: () => void;
}

export const Row = ({
  children,
  hasCheckbox = false,
  style,
  handleClick,
}: Props) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleChecklistClick = () => {
    setIsChecked(previousValue => !previousValue);
  };

  return (
    <div className={styles.row} style={style} onClick={handleClick}>
      {children}

      {hasCheckbox && (
        <div className={styles.checkbox}>
          <Checkbox
            handleChange={handleChecklistClick}
            isChecked={isChecked}
            name="checklist"
            theme="black"
            style={{
              width: '1rem',
              height: '1rem',
            }}
          />
        </div>
      )}
    </div>
  );
};
