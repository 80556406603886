import { useState } from 'react';
import { changeQuotationStateController } from '../controller';
import { Quotation } from '../../../../../@types/interface';
import { ChangeQuotationStateActions } from '../interface';

interface Props {
  onChangeState?: (quotation: Quotation) => void;
}

interface ChangeQuotationStateProps {
  id: string;
  action: ChangeQuotationStateActions;
}

export const useChangeQuotationState = ({ onChangeState }: Props) => {
  const [isChangingQuotationState, setIsChangingQuotationState] =
    useState(false);
  const [isChangingQuotationModalOpen, setIsChangingQuotationModalOpen] =
    useState(false);

  const changeQuotationState = async (props: ChangeQuotationStateProps) => {
    setIsChangingQuotationState(true);

    const res = await changeQuotationStateController({ ...props });

    if (res && onChangeState) {
      onChangeState(res);
    }
    setIsChangingQuotationState(false);
  };

  const handleFinishModal = (option: 'open' | 'close') => {
    if (option === 'open') setIsChangingQuotationModalOpen(true);
    if (option === 'close') setIsChangingQuotationModalOpen(false);
  };

  return {
    isChangingQuotationState,
    isChangingQuotationModalOpen,
    handleFinishModal,
    changeQuotationState,
  };
};
