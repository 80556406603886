/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import styles from './header.module.css';
import listIcon from '../../../../../assets/icons/List.svg';
import { setOpen } from '../../../../../features/modalLanginPage/openModal';
import { useAppSelector } from '../../../../../hooks/useAppSelector';
import { setColor } from '../../../../../features/modalLanginPage/color';

import logo from '../../../../../assets/logo_autocenter.svg';
import logoText from '../../../../../assets/logo_autocenter_text.svg';
import logoBrand from '../../../../../assets/logo_autocenter_brand.svg';
import dropdown from '../../../../../assets/icons/dropdown.svg';
import { Button } from '../../../../../shared/Button';

interface Props {
  setTypeService?: (value: string) => void;
}

export const HeaderLandingPage = ({ setTypeService }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const { route } = useAppSelector(state => state.activeColor);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClose = (e: any) => {
    if (e.target.id === 'clickSideBar') {
      setIsOpen(!isOpen);
    }
  };

  const handleClick = (uri: string) => {
    dispatch(setColor({ route: uri }));
    if (uri === 'register') {
      dispatch(setOpen({ show: true }));
      setIsOpen(false);
    } else {
      navigate(uri);
    }
  };

  return (
    <header className={styles.header}>
      <img src={logo} alt="Autocenter" className={styles['logo-icon']} />
      <div className={styles['header-mobile']}>
        <img
          src={logoBrand}
          alt="autocenter marca"
          className={styles['logo-brand']}
        />
        <img src={logoText} alt="autocenter" className={styles['logo-text']} />
        <button type="button" onClick={() => setIsOpen(!isOpen)}>
          <img className={styles.listIcon} src={listIcon} alt="MenuSideBar" />
        </button>
      </div>
      <nav>
        <div className={styles.navbar_mainContent}>
          <button
            type="button"
            onClick={() => handleClick('/landing-page')}
            style={{ color: route === '/landing-page' ? '#007a37' : '' }}
          >
            Inicial
          </button>
          <div className={styles.services}>
            <div
              style={{ color: route === '/page-services' ? '#007a37' : '' }}
              className={styles['services-text']}
            >
              Serviços
              <img src={dropdown} alt="opções de serviços" />
            </div>
            <ul className={styles['services-list']}>
              <li>
                <Button
                  handleClick={() => {
                    if (setTypeService) setTypeService('OFICINAS');
                    handleClick('/page-services');
                  }}
                  variant="unstyled"
                >
                  OFICINA
                </Button>
              </li>
              <li>
                <Button
                  handleClick={() => {
                    if (setTypeService) setTypeService('POSTOS');
                    handleClick('/page-services');
                  }}
                  variant="unstyled"
                >
                  POSTO
                </Button>
              </li>
              <li>
                <Button
                  handleClick={() => {
                    if (setTypeService) setTypeService('FROTAS');
                    handleClick('/page-services');
                  }}
                  variant="unstyled"
                >
                  FROTA
                </Button>
              </li>
            </ul>
          </div>
          <button
            type="button"
            onClick={() => handleClick('/page-aboutUs')}
            style={{ color: route === '/page-aboutUs' ? '#007a37' : '' }}
          >
            Sobre Nós
          </button>
          <button
            type="button"
            onClick={() => handleClick('/page-contact')}
            style={{ color: route === '/page-contact' ? '#007a37' : '' }}
          >
            Contato
          </button>
          <button
            type="button"
            onClick={() => handleClick('/page-login')}
            style={{ color: route === '/page-login' ? '#007a37' : '' }}
          >
            Login
          </button>
        </div>
        <Button
          handleClick={() => handleClick('register')}
          variant="register"
          theme="pulse"
          style={{
            fontWeight: 400,
            paddingInline: '1rem',
            fontSize: '1.6rem',
          }}
        >
          teste gratuitamente
        </Button>
      </nav>
      {isOpen && (
        <div
          className={styles.ovelaySideBar}
          onClick={e => handleClose(e)}
          id="clickSideBar"
        >
          <div className={styles.wrapperSideBar}>
            <nav>
              <div className={styles.navBar}>
                <button
                  type="button"
                  onClick={() => handleClick('/landing-page')}
                  style={{ color: route === '/landing-page' ? '#007a37' : '' }}
                >
                  Inicial
                </button>
                <div className={styles.services}>
                  <input type="checkbox" />
                  <div
                    style={{
                      color: route === '/page-services' ? '#007a37' : '',
                    }}
                    className={styles['services-text']}
                  >
                    Serviços
                    <img src={dropdown} alt="opções de serviços" />
                  </div>
                  <ul className={styles['services-list']}>
                    <li>
                      <Button
                        handleClick={() => {
                          if (setTypeService) setTypeService('OFICINAS');
                          handleClick('/page-services');
                        }}
                        variant="unstyled"
                      >
                        OFICINA
                      </Button>
                    </li>
                    <li>
                      <Button
                        handleClick={() => {
                          if (setTypeService) setTypeService('POSTOS');
                          handleClick('/page-services');
                        }}
                        variant="unstyled"
                      >
                        POSTO
                      </Button>
                    </li>
                    <li>
                      <Button
                        handleClick={() => {
                          if (setTypeService) setTypeService('FROTAS');
                          handleClick('/page-services');
                        }}
                        variant="unstyled"
                      >
                        FROTA
                      </Button>
                    </li>
                  </ul>
                </div>
                <button
                  type="button"
                  onClick={() => handleClick('/page-aboutUs')}
                  style={{ color: route === '/page-aboutUs' ? '#007a37' : '' }}
                >
                  Sobre Nós
                </button>
                <button
                  type="button"
                  onClick={() => handleClick('/page-contact')}
                  style={{ color: route === '/page-contact' ? '#007a37' : '' }}
                >
                  Contato
                </button>
                <button
                  type="button"
                  onClick={() => handleClick('/page-login')}
                  style={{ color: route === '/page-login' ? '#007a37' : '' }}
                >
                  Login
                </button>
              </div>
              <Button
                handleClick={() => handleClick('register')}
                variant="register"
                theme="pulse"
                style={{
                  fontWeight: 400,
                  paddingInline: '1rem',
                  fontSize: '1.25rem',
                }}
              >
                teste gratuitamente
              </Button>
            </nav>
          </div>
        </div>
      )}
    </header>
  );
};
