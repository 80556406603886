import { CSSProperties } from 'react';
import styles from './loading.module.css';

interface Props {
  variant?: 'rect' | 'img';
  style?: CSSProperties;
}

export const Loading = ({ variant = 'rect', style }: Props) => {
  return (
    <div className={`${styles.container} ${styles[variant]}`} style={style} />
  );
};
