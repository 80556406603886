/* eslint-disable react/jsx-no-constructed-context-values */
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';

import { User } from '../@types/User';
import { Workshop } from '../@types/interface';

interface IUserProvider {
  user: User;
  workshop: Workshop | null;

  setUser: React.Dispatch<React.SetStateAction<User>>;
  setWorkshop: React.Dispatch<React.SetStateAction<Workshop>>;
}

const AuthContext = createContext({} as IUserProvider);

const AuthProvider: React.FC<PropsWithChildren<any>> = ({ children }) => {
  const [user, setUser] = useState<User>({} as User);
  const [workshop, setWorkshop] = useState<Workshop>({} as Workshop);

  const loggedUserVerification = (userValue: string) => {
    if (!userValue) return;

    setUser(JSON.parse(userValue));
    const workshopInfo = JSON.parse(userValue);
    setWorkshop(workshopInfo.workshop);
  };

  useEffect(() => {
    const userJson = localStorage.getItem('@AutoCenter: user');
    if (userJson) loggedUserVerification(userJson);
  }, []);

  return (
    <AuthContext.Provider value={{ user, setUser, workshop, setWorkshop }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => useContext(AuthContext);
