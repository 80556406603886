import React, { CSSProperties } from 'react';
import styles from './root.module.css';

interface Props {
  children: React.ReactNode;
  heading?: React.ReactNode;
  style?: CSSProperties;
}

export const Root = ({ children, heading, style }: Props) => {
  return (
    <div className={styles.container} style={style}>
      <div className={styles.heading}>{heading}</div>
      {children}
    </div>
  );
};
