import { AlertMessage } from '../../../../../../../../../shared/AlertMessage';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onFinish: () => void;
  container: Element | DocumentFragment;
}

export const ConfirmFinishModal = ({
  isOpen,
  onClose,
  onFinish,
  container,
}: Props) => {
  return (
    <AlertMessage.Root
      isOpen={isOpen}
      onClose={onClose}
      title="Confirmar Finalização"
      container={container}
      footer={
        <AlertMessage.Footer>
          <>
            <AlertMessage.CancelButton onClick={onFinish}>
              finalizar Cotação
            </AlertMessage.CancelButton>
            <AlertMessage.ConfirmButton onClick={onClose}>
              Voltar para Cotação
            </AlertMessage.ConfirmButton>
          </>
        </AlertMessage.Footer>
      }
    >
      <AlertMessage.Body>
        <p>
          Deseja finalizar a cotação? Ela não poderá mais receber nenhuma
          resposta!
        </p>
      </AlertMessage.Body>
    </AlertMessage.Root>
  );
};
