import {
  CSSProperties,
  ChangeEvent,
  InputHTMLAttributes,
  useEffect,
  useState,
} from 'react';
import styles from './checkbox.module.css';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  handleChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  isChecked?: boolean;
  theme?: 'default' | 'green' | 'lightgreen' | 'black';
  style?: CSSProperties;
  customSize?: 'default' | 'small';
}

export const Checkbox = ({
  handleChange,
  name,
  label,
  isChecked = false,
  theme = 'default',
  customSize = 'default',
  style,
  ...rest
}: Props) => {
  const uniqueId = Date.now().toString() + Math.random().toString();

  const checkboxId = `${name}-checkbox-${uniqueId}`;
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    setIsSelected(isChecked);
  }, [isChecked]);

  return (
    <div
      className={`${styles['custom-checkbox']} ${styles[theme]} ${styles[customSize]}`}
      style={{
        ...style,
      }}
    >
      <input
        type="checkbox"
        id={checkboxId}
        onChange={e => {
          if (handleChange) handleChange(e);
          setIsSelected(previousValue => !previousValue);
        }}
        checked={isSelected}
        className={styles.checkbox}
        {...rest}
      />
      <label htmlFor={checkboxId}>{label}</label>
    </div>
  );
};
