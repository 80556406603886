import React from 'react';
import styles from './head-item.module.css';

interface Props {
  children?: React.ReactNode;
}

export const HeadItem = ({ children }: Props) => {
  return <div className={styles.item}>{children}</div>;
};
