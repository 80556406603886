import { CSSProperties, ReactNode, useEffect, useRef } from 'react';
import styles from './body.module.css';

interface Props {
  children: ReactNode;
  onScroll: () => void;
  style?: CSSProperties;
}

export const Body = ({ children, onScroll, style }: Props) => {
  const contentRef = useRef<null | HTMLDivElement>(null);
  useEffect(() => {
    const listContent = contentRef.current;

    const handleScroll = () => {
      if (
        listContent &&
        listContent.scrollTop + listContent.clientHeight >=
          listContent.scrollHeight * 0.9
      ) {
        onScroll();
      }
    };

    listContent?.addEventListener('scroll', handleScroll);
    return () => {
      listContent?.removeEventListener('scroll', handleScroll);
    };
  }, [onScroll]);

  return (
    <div className={styles.wrapper} style={style}>
      <div className={styles.content} ref={contentRef}>
        {children}
      </div>
    </div>
  );
};
