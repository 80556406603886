import * as yup from 'yup';
import { LoginInput } from './interface';
import { authenticate } from './model';
import message from '../../../../../utils/message';

export const schema = yup.object({
  email: yup.string().required('Esse campo é obrigatório'),
  password: yup
    .string()
    .required('informe uma senha')
    .min(6, 'A senha deve ter no mínimo 6 digitos'),
});

export const authenticateController = async (props: LoginInput) => {
  try {
    await schema.validate(props, {
      abortEarly: false,
    });
    const res = await authenticate(props);
    return res;
  } catch (error) {
    if (error instanceof yup.ValidationError) {
      message(error.errors[0], 'warning');
    } else {
      message(error, 'error');
    }
  }
};
