import { useState } from 'react';
import { X, XSquare } from 'phosphor-react';
import api from '../../../../../../../services/api/api';
import logoIcon from '../../../../../../../assets/img/logo/logoAutocenterImagemBlack.svg';
import overlay from '../../../../../../../assets/img/overlayforget.svg';
import {
  maskCpfCnpj,
  numberOnly,
} from '../../../../../../../services/helpers/mask';
import message from '../../../../../../../utils/message';
import styles from './ForgetModal.module.css';

interface Props {
  isOpen: boolean;
  setIsOpen: (data: boolean) => void;
}

export const ForgetModalLandingPage = ({ isOpen, setIsOpen }: Props) => {
  const [document, setDocument] = useState<string>('');

  const handleRecoveryPassword = async () => {
    try {
      await api
        .post('/recover', {
          role: 'agent',
          phone: `${numberOnly(document)?.trim()}`,
          selected_method: 'phone',
        })
        .then(response => {
          setIsOpen(false);
          message('um sms foi enviado para o telefone informado', 'success');
        });
    } catch (error) {
      setIsOpen(false);
      message(error, 'error');
    }
    setDocument('');
  };

  return (
    <div
      className={styles.overlay}
      style={{ display: isOpen ? 'flex' : 'none' }}
    >
      <div className={styles.content}>
        <button type="button" onClick={() => setIsOpen(false)}>
          <XSquare size={30} color="#E53835" />
        </button>
        <img className={styles.overlayImage} src={overlay} alt="overlayImage" />

        <div className={styles.header}>
          <div className={styles.title}>
            <img className={styles.logoImage} src={logoIcon} alt="imageLogo" />
            <div>
              <h1>recuperar senha</h1>
            </div>
          </div>
          <h2>Informe o documento cadastrado</h2>
        </div>

        <div className={styles.form}>
          <h2>CNPJ/CPF:</h2>
          <input
            type="text"
            className={styles.formInput}
            placeholder="28.080.222/0001-00"
            value={document}
            onChange={(e: any) => {
              setDocument(maskCpfCnpj(e.target.value));
            }}
          />
        </div>

        <div className={styles.groupButton}>
          <button type="button" onClick={() => handleRecoveryPassword()}>
            Recuperar
          </button>
        </div>
      </div>
    </div>
  );
};
