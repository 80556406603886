import { Loading } from '../../../../../../../../../shared/Loading';
import styles from './description-loading.module.css';

export const DescriptionLoading = () => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Loading
          style={{
            height: '2.5rem',
          }}
        />
        <Loading
          style={{
            height: '1.5rem',
          }}
        />
      </div>
      <div className={styles.body}>
        <Loading />
        <Loading />
        <Loading />
        <Loading
          style={{
            height: '7rem',
          }}
        />
        <div className={styles['images-list']}>
          <Loading variant="img" />
          <Loading variant="img" />
          <Loading variant="img" />
        </div>
      </div>
    </div>
  );
};
