import React, { useEffect, useState } from 'react';
import { X } from '@phosphor-icons/react';
import styles from './filter-component.module.css';
import { FilterMenu, FilterMenuOptions } from '../FilterMenu';
import { Input } from '../Input';
import { Button } from '../Button';

interface Props {
  variant: 'searchbar' | 'date';
  defaultValue?: string;
  options: FilterMenuOptions[];
  handleSearch: (query: string) => void;
  handleDateSearch: (period: string[]) => void;
  handleFilterChange: (filter: string) => void;
  upperCase?: boolean;
  onlyNumber?: boolean;
}

export const FilterComponent = ({
  options,
  variant,
  defaultValue = '',
  handleSearch,
  handleDateSearch,
  handleFilterChange,
  upperCase = false,
  onlyNumber = false,
}: Props) => {
  const [initialDate, setInitialDate] = useState('');
  const [finalDate, setFinalDate] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [filterName, setFilterName] = useState(
    options.find(option => option.value === defaultValue)?.label || '',
  );

  const handleDateChange = (value: string) => {
    const date = new Date(value || '').toISOString().split('T')[0];
    return date;
  };

  const handleClear = () => {
    if (variant === 'searchbar') {
      handleSearch('');
    }
    if (variant === 'date') {
      handleDateSearch([]);
    }
    setInitialDate('');
    setFinalDate('');
    setSearchQuery('');
  };

  const handleFilter = (value: string) => {
    handleFilterChange(value);
    setFilterName(options.find(option => option.value === value)?.label || '');
    handleClear();
  };

  useEffect(() => {
    if (variant === 'date') {
      handleDateSearch([initialDate, finalDate]);
    }
  }, [initialDate, finalDate, variant, handleDateSearch]);

  return (
    <div className={styles['filter-container']}>
      <div className={styles.filters}>
        {variant === 'searchbar' && (
          <Input
            name="search"
            placeholder={`Pesquisar ${filterName}`}
            type="search"
            style={{
              border: '1px solid #A5A5A5',
              background: 'transparent',
              paddingTop: '1.5rem',
              paddingBottom: '1.5rem',
              paddingRight: '2rem',
            }}
            value={searchQuery}
            handleChange={e => {
              let newValue = e.target.value;
              if (upperCase) newValue = newValue.toUpperCase();
              if (onlyNumber) newValue = newValue.replace(/\D/g, '');

              handleSearch(newValue);

              setSearchQuery(newValue);
            }}
          />
        )}
        {variant === 'date' && (
          <div className={styles['date-container']}>
            <Input
              type="date"
              name="initialDate"
              handleChange={e =>
                setInitialDate(handleDateChange(e.target.value))
              }
              value={initialDate}
            />
            <Input
              type="date"
              name="finalDate"
              handleChange={e => setFinalDate(handleDateChange(e.target.value))}
              value={finalDate}
            />
          </div>
        )}
        <FilterMenu
          options={options}
          defaultValue={defaultValue}
          handleChange={handleFilter}
        />
      </div>
      {(initialDate.trim().length > 0 ||
        finalDate.trim().length > 0 ||
        searchQuery.trim().length > 0) && (
        <Button
          handleClick={handleClear}
          variant="unstyled"
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '.5rem',
            fontWeight: 500,
          }}
        >
          <X size={20} />
          limpar busca
        </Button>
      )}
    </div>
  );
};
