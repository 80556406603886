import { ListTable } from '../../../../../../shared/ListTable';
import { AnswerQuotation } from '../../../../../../@types/interface';
import { useResponsive } from '../../../../../../hooks/useResponsive';
import { useQuotationAnswer } from '../../hooks/useQuotationAnswer';

interface Props {
  answer: AnswerQuotation;
  handleView: () => void;
  onRowClick: () => void;
  handleWhatsapp: () => void;
  isSelected: boolean;
}

export const FinishedQuotationRow = ({
  answer,
  handleView,
  onRowClick,
  isSelected,
  handleWhatsapp,
}: Props) => {
  const { sizeMobile } = useResponsive();
  const { handleShowDate } = useQuotationAnswer();
  return (
    <>
      <ListTable.Row
        style={{
          gridTemplateColumns: !sizeMobile
            ? '1.5rem 1fr 2.5fr 1fr 1fr'
            : '0.5fr 0.5fr 1fr 1fr 2fr',
          gap: sizeMobile ? '0.5rem' : '0',
        }}
        selected={isSelected}
        variant="caret"
        handleClick={onRowClick}
      >
        <ListTable.RowItem
          style={{
            display: 'flex',
            paddingLeft: !sizeMobile ? '2rem' : '1rem',
          }}
        >
          {answer?.quotation?.QUOTATION ?? '----'}
        </ListTable.RowItem>
        <ListTable.RowItem>
          {handleShowDate(answer?.quotation?.created_at ?? '---')}
        </ListTable.RowItem>
        <ListTable.RowItem>
          {handleShowDate(answer?.quotation?.updated_at ?? '---')}
        </ListTable.RowItem>
        <ListTable.RowItem>
          {answer?.quotation?.workshop?.fantasy_name ?? '----'}
        </ListTable.RowItem>
      </ListTable.Row>
      {isSelected && (
        <ListTable.SubRow>
          <ListTable.ViewButton onClick={handleView} />
          <ListTable.WhatsappButton onClick={handleWhatsapp} />
        </ListTable.SubRow>
      )}
    </>
  );
};
