import { Wrench, Users, Star, SignOut } from '@phosphor-icons/react';
import { Bell } from 'phosphor-react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './home.module.css';
import homeLogo from '../../../assets/icons/home-logo.svg';
import pattern1 from '../../../assets/img/pattern-1.svg';
import pattern2 from '../../../assets/img/pattern-2.svg';
import pattern3 from '../../../assets/img/pattern-3.svg';
import { sendWhatsappMessage } from '../../../utils/sendWhatsappMessage';
import { COLORS } from '../../../styles/colors';
import { useHomeData } from './useHomeData';
import { useHomeCards } from './useHomeCards';
import { removeObjectFromLocal } from '../../../utils/localStorage';
import { AnswerIdDetectionModal } from './components/AnswerIdDetectionModal';
// import { useAuthRefresh } from '../../../hooks/useAuthRefresh';
import { ActionCard } from './components/ActionCard';
import { useQuotation } from '../Quotation/CreateQuotation/hooks/useQuotation';
import { useQuotationAnswer } from '../Quotation/AnswerQuotation/hooks/useQuotationAnswer';
import { getUserData } from '../../../utils/workshopData';
import { useAuthRefresh } from '../../../hooks/useAuthRefresh';

export const Home = () => {
  const {
    handleSingOut,
    isVisitor,
    newAnswerQuotations,
    newQuotations,
    isAnswerQuotationModalOpen,
    handleAnswerQuotation,
    handleCancelAnswerQuotation,
  } = useHomeData();

  const { content } = useHomeCards({
    newQuotationAnswers: newAnswerQuotations.length,
    newQuotations: newQuotations.length,
  });

  const userData = getUserData();

  const navigate = useNavigate();

  useQuotation();
  useQuotationAnswer();
  // useAuthRefresh();

  useEffect(() => {
    removeObjectFromLocal('@local-order-service');
    if (userData && userData.agents && userData.agents[0].is_first_access) {
      alert('Por favor redefina sua senha');
      localStorage.setItem('hasToChangePassword', 'true');
      navigate('/profile');
    }
  }, []);

  return (
    <>
      <AnswerIdDetectionModal
        isOpen={isAnswerQuotationModalOpen}
        onClose={handleCancelAnswerQuotation}
        onConfirm={handleAnswerQuotation}
      />
      <main className={styles.main}>
        <header className={styles.header}>
          <div className={styles['logo-wrapper']}>
            <img src={homeLogo} className={styles.logo} alt="autocenter logo" />
          </div>
          <div className={styles.statistics}>
            <img src={pattern1} className={styles.pattern} alt="" />
          </div>
        </header>
        <section className={styles.actions}>
          {isVisitor && (
            <ActionCard
              description="Clique aqui para seu mês grátis e acesse recursos imperdíveis"
              icon={
                <Star
                  size={window.innerWidth === 480 ? 38 : 48}
                  weight="bold"
                  color="#007A37"
                />
              }
              title="Faça parte do Autocenter"
              path=""
              onClick={() => {
                sendWhatsappMessage(
                  'Olá! Quero fazer parte do Autocenter',
                  '+558791080605',
                );
              }}
            />
          )}
          {content.map(item => (
            <ActionCard
              description={item.description}
              icon={item.icone}
              title={item.title}
              path={item.uri}
              notifications={item.notifications}
              key={item.title}
            />
          ))}
          <img src={pattern2} className={styles.pattern} alt="" />
          <img
            src={pattern3}
            className={`${styles.pattern} ${styles.mobile}`}
            alt=""
          />
        </section>
        <button
          type="button"
          className={styles.singout}
          onClick={handleSingOut}
        >
          <SignOut size={32} color={COLORS.RED} weight="bold" />
        </button>
      </main>
    </>
  );
};
