import { CopySimple, FileText, GridFour } from '@phosphor-icons/react';
import { useState } from 'react';
import { Circle } from '../../../../../../shared/Circle';
import { PageLayout } from '../../../../../../shared/PageLayout';
import { Quotation } from '../../../../../../@types/interface';
import styles from './little-header-quotation.module.css';

type Props = {
  answeredQuotationList: Quotation[];
  setSelectedPage: (screen: 'created' | 'replies' | 'completed') => void;
  selectedPage: 'created' | 'replies' | 'completed';
};

export const LittleHeaderCreateQuotation = ({
  answeredQuotationList,
  setSelectedPage,
  selectedPage,
}: Props) => {
  const [dropdownOpen, setDropdownOpen] = useState(true);
  const handleNameButton = () => {
    if (selectedPage === 'created') {
      return ['Criadas', <GridFour size={32} weight="fill" />];
    }
    if (selectedPage === 'replies') {
      return ['Respostas', <FileText size={32} weight="fill" />];
    }

    return ['Finalizadas', <CopySimple size={32} weight="fill" />];
  };

  const handleHideButton = () => {
    if (selectedPage === 'created') {
      return 'created';
    }
    if (selectedPage === 'replies') {
      return 'replies';
    }

    return 'completed';
  };

  return (
    <div className={styles.contentButton}>
      <PageLayout.TopMenuButton
        onClick={() => console.log('setDropdownOpen(prev => !prev')}
        isSelected
      >
        {handleNameButton()[1]}
        {handleNameButton()[0]}
        {selectedPage === 'replies' &&
          answeredQuotationList.length > 0 &&
          ` (${answeredQuotationList.length})`}{' '}
        {selectedPage === 'replies' && answeredQuotationList.length > 0 && (
          <Circle animation="pulse" variant="notification" />
        )}
      </PageLayout.TopMenuButton>

      {dropdownOpen && (
        <div
          className={`${styles.containerDropDownOpen} ${
            styles[handleHideButton()]
          }`}
        >
          <PageLayout.TopMenuButton
            isSelected={selectedPage === 'created'}
            onClick={() => setSelectedPage('created')}
          >
            <GridFour size={32} weight="fill" />
            Criadas
          </PageLayout.TopMenuButton>
          <PageLayout.TopMenuButton
            isSelected={selectedPage === 'replies'}
            onClick={() => {
              setSelectedPage('replies');
            }}
          >
            <FileText size={32} weight="fill" />
            Respondidas
            {answeredQuotationList.length > 0 &&
              ` (${answeredQuotationList.length})`}{' '}
            {answeredQuotationList.length > 0 && (
              <Circle animation="pulse" variant="notification" />
            )}
          </PageLayout.TopMenuButton>
          <PageLayout.TopMenuButton
            isSelected={selectedPage === 'completed'}
            onClick={() => setSelectedPage('completed')}
          >
            <CopySimple size={32} weight="fill" />
            Finalizadas
          </PageLayout.TopMenuButton>
        </div>
      )}
    </div>
  );
};
