import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Partner } from './interfaces';

interface PartnersState {
  partners: Partner[];
}

const initialState: PartnersState = {
  partners: [],
};

export const PartnersSlice = createSlice({
  name: 'partners',
  initialState,
  reducers: {
    setPartners: (state, action: PayloadAction<Partner[]>) => {
      state.partners = action.payload;
    },
    removePartner: (state, action: PayloadAction<string>) => {
      const filteredPartners = state.partners.filter(
        partner => partner.id_Partners !== action.payload,
      );
      state.partners = filteredPartners;
    },
    addPartner: (state, action: PayloadAction<Partner>) => {
      state.partners = [...state.partners, { ...action.payload }];
    },
    updatePartnerStatus: (state, action: PayloadAction<string>) => {
      const updatedPartners = state.partners.map(partner => {
        if (partner.id_Partners !== action.payload) {
          return partner;
        }
        return { ...partner, active: !partner.active };
      });
      state.partners = updatedPartners;
    },
    updatePartnerData: (state, action: PayloadAction<Partner>) => {
      const updatedPartners = state.partners.map(partner => {
        if (partner.id_Partners !== action.payload.id_Partners) {
          return partner;
        }
        return { ...partner, ...action.payload };
      });
      state.partners = updatedPartners;
    },
  },
});

export const {
  setPartners,
  removePartner,
  addPartner,
  updatePartnerStatus,
  updatePartnerData,
} = PartnersSlice.actions;
export const PartnersReducer = PartnersSlice.reducer;
