import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { UseFormReset } from 'react-hook-form';
import {
  createQuotationController,
  getQuotationByIdController,
} from '../../../controller';
import { addNotAnsweredQuotations } from '../../../../../../../features/quotation/quotation-reply-slice';
import { Quotation } from '../../../../../../../@types/interface';
import { FormValues } from './useQuotationForm';

export interface Quote {
  description: string;
  reference: string;
  quantity: string;
}

interface CreateQuotationProps {
  items: Quote[];
  vehicleId: string;
  comment: string;
  imagesList: File[];
  hours?: string;
  type_payment?: string;
}

interface Props {
  defaultQuotation: Quotation | null;
  shouldSearchQuotation: boolean;
  reset: UseFormReset<FormValues>;
}

export const useEditQuotation = ({
  defaultQuotation,
  shouldSearchQuotation,
  reset,
}: Props) => {
  const [isCreating, setIsCreating] = useState(false);
  const dispatch = useDispatch();

  const fetchQuotation = useCallback(
    async (id: string) => {
      const res = await getQuotationByIdController(id);
      if (res) {
        reset({
          vehicleId: res?.vehicle_id ?? '',
          chassi: res?.vehicle?.chassi ?? '',
          model: res?.vehicle?.model ?? '',
          plate: res?.vehicle?.license_plate ?? '',
          quotes: res.items_quotation,
          vehicle: res?.vehicle?.name ?? '',
          year: res?.vehicle?.year ?? '',
          comment: res?.comment ?? '',
        });
      }
    },
    [reset],
  );

  const createQuotation = async ({
    comment,
    items,
    vehicleId,
    imagesList,
    hours,
    type_payment,
  }: CreateQuotationProps) => {
    setIsCreating(true);
    const createQuotationRequestBody: CreateQuotationProps = {
      items,
      vehicleId,
      comment: comment ?? '',
      imagesList,
    };
    if (hours) createQuotationRequestBody.hours = hours;
    if (type_payment) createQuotationRequestBody.type_payment = type_payment;

    const res = await createQuotationController({
      ...createQuotationRequestBody,
    });

    if (res) {
      dispatch(addNotAnsweredQuotations([res]));
    }
    setIsCreating(false);
    return res;
  };

  useEffect(() => {
    if (defaultQuotation && shouldSearchQuotation)
      fetchQuotation(defaultQuotation.id_quotation);
  }, [defaultQuotation, fetchQuotation, shouldSearchQuotation]);

  return {
    createQuotation,
    isCreating,
    fetchQuotation,
  };
};
