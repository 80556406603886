import { FileText, Question } from '@phosphor-icons/react';
import { ListTable } from '../../../../../../shared/ListTable';
import { Quotation } from '../../../../../../@types/interface';
import { Button } from '../../../../../../shared/Button';
import { useResponsive } from '../../../../../../hooks/useResponsive';
import { Circle } from '../../../../../../shared/Circle';
import { useQuotation } from '../../hooks/useQuotation';
import { CountDownTimer } from '../../../../../../shared/CountDownTimer';

interface Props {
  quotation: Quotation;
  handleViewReplies: () => void;
  handleFinish: () => void;
  isSelected: boolean;
  onRowClick: () => void;
  handleShowBlockedMessage: (option: 'open' | 'close') => void;
}

export const QuotedRepliesRow = ({
  quotation,
  handleViewReplies,
  isSelected,
  onRowClick,
  handleFinish,
  handleShowBlockedMessage,
}: Props) => {
  const { sizeMobile } = useResponsive();
  const { handleShowDate } = useQuotation();
  const handleQuotationStatus = () => {
    // if (quotation.blocked) return 'disabled';
    if (quotation && quotation.new_offers) return 'notification';
    return 'default';
  };
  return (
    <>
      <ListTable.Row
        style={{
          gridTemplateColumns: sizeMobile
            ? '1.5rem .5fr repeat(2, 2.3fr) 1.5fr'
            : '1.8rem repeat(5, 1fr)',
          color: quotation.blocked ? 'var(--grey)' : '',
        }}
        selected={isSelected}
        variant="caret"
        handleClick={onRowClick}
      >
        <ListTable.RowItem
          style={{
            display: 'flex',
            paddingLeft: sizeMobile ? '1rem' : '2rem',
          }}
        >
          <Circle
            variant={handleQuotationStatus()}
            style={{
              marginRight: '.5rem',
            }}
          />
          {quotation.QUOTATION ?? '----'}
        </ListTable.RowItem>
        {/* {!sizeMobile && (
          <ListTable.RowItem>
            <CountDownTimer
              expirationHours={quotation?.hours ?? 0}
              initialDate={
                quotation?.created_at
                  ? new Date(quotation?.created_at)
                  : new Date()
              }
            />
          </ListTable.RowItem>
        )} */}
        <ListTable.RowItem>
          {handleShowDate(quotation.created_at)}
        </ListTable.RowItem>
        <ListTable.RowItem>
          {handleShowDate(quotation.updated_at)}
        </ListTable.RowItem>
        <ListTable.RowItem>
          {quotation.number_answer || '0'} de{' '}
          {quotation?.answers_quotation?.length ?? 0}
        </ListTable.RowItem>
      </ListTable.Row>
      {isSelected && (
        <ListTable.SubRow>
          {/* {sizeMobile && (
            <CountDownTimer
              expirationHours={quotation?.hours ?? 0}
              initialDate={quotation?.created_at ?? new Date()}
            />
          )} */}
          {quotation.blocked ? (
            <Button handleClick={() => handleShowBlockedMessage('open')}>
              <Question size={20} weight="bold" />
              Respostas encerradas
            </Button>
          ) : (
            <Button handleClick={handleViewReplies}>
              <FileText size={20} weight="bold" />
              Avaliar Propostas
            </Button>
          )}
          <ListTable.DeleteButton onClick={handleFinish} text="Finalizar" />
        </ListTable.SubRow>
      )}
    </>
  );
};
