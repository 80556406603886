import { CSSProperties, useState } from 'react';
import { PlusSquare, MinusSquare, Timer } from '@phosphor-icons/react';
import { useDispatch } from 'react-redux';
import { Input } from '../../../../../../../../../shared/Input';
import styles from './quote-description.module.css';
import { Textarea } from '../../../../../../../../../shared/Textarea';
import { Quotation } from '../../../../../../../../../@types/interface';
import { HorizontalBannerList } from '../../../../../../../../../shared/HorizontalBannerList';
import { useResponsive } from '../../../../../../../../../hooks/useResponsive';
import { Button } from '../../../../../../../../../shared/Button';
import { useChangeQuotationState } from '../../../../../hooks/useChangeQuotationState';
import { removeAnsweredQuotation } from '../../../../../../../../../features/quotation/quotation-reply-slice';
import { ConfirmFinishModal } from '../ConfirmFinishModal';
import { DescriptionLoading } from '../DescriptionLoading';
import {
  formatDate,
  formatTime,
} from '../../../../../../../../../utils/dateFunctions';
import { useCountdownTimer } from '../../../../../../../../../hooks/useCountdownTimer';

interface Props {
  quotation: Quotation | null;
  onClose: () => void;
  container: Element | DocumentFragment;
  isLoading: boolean;
}

const inputStyles: CSSProperties = {
  border: 'none',
  fontWeight: 600,
  color: '#000000',
};

export const QuoteDescription = ({
  quotation,
  onClose,
  container,
  isLoading,
}: Props) => {
  const [ViewMore, setViewMora] = useState(false);
  const { sizeMobile } = useResponsive();
  const dispatch = useDispatch();

  const {
    changeQuotationState,
    isChangingQuotationModalOpen,
    handleFinishModal,
  } = useChangeQuotationState({
    onChangeState: () => {
      dispatch(removeAnsweredQuotation(quotation?.id_quotation || ''));
      onClose();
    },
  });
  // const { timer } = useCountdownTimer({
  //   expirationHours: quotation?.hours ?? 0,
  //   initialDate: quotation?.created_at ?? new Date(),
  // });

  const handleDateTime = (value: Date) => {
    const newDate = new Date(value);
    const formattedDate = formatDate(newDate);
    const formattedTime = formatTime(newDate);
    return `${formattedDate} às ${formattedTime}`;
  };

  return (
    <>
      <ConfirmFinishModal
        isOpen={isChangingQuotationModalOpen}
        onClose={() => {
          handleFinishModal('close');
        }}
        onFinish={() => {
          changeQuotationState({
            id: quotation?.id_quotation ?? '',
            action: 'finish',
          });
          handleFinishModal('close');
        }}
        container={container}
      />
      <div className={styles.container}>
        {isLoading ? (
          <DescriptionLoading />
        ) : (
          <>
            <h1 className={styles.title}>
              Cotação N° {quotation?.QUOTATION || ''}
            </h1>
            {quotation && (
              <div className={styles.info}>
                {' '}
                <h3 className={styles.subtitle}>
                  criada em {handleDateTime(quotation.created_at)}
                </h3>
                {/* {timer.length > 0 && (
                  <span className={styles.timer}>
                    <Timer size={24} color="var(--error)" weight="bold" />{' '}
                    {timer !== 'expired'
                      ? `Expira em ${timer}`
                      : 'Cotação expirada'}
                  </span>
                )} */}
              </div>
            )}

            {sizeMobile && (
              <div className={styles.buttonViewMore}>
                {ViewMore ? (
                  <>
                    <PlusSquare
                      onClick={() => setViewMora(prev => !prev)}
                      size={32}
                      color="#007a36"
                    />
                    <span>ver mais</span>
                  </>
                ) : (
                  <>
                    <MinusSquare
                      onClick={() => setViewMora(prev => !prev)}
                      size={32}
                      color="#007a36"
                    />
                    <span>ver menos</span>
                  </>
                )}
              </div>
            )}

            <ul
              className={`${styles['info-list']} ${
                ViewMore && sizeMobile ? styles.viewMore : ''
              }`}
            >
              <li>
                <Input
                  name=""
                  label="Placa:"
                  value={quotation?.vehicle?.license_plate ?? ''}
                  disabled
                  style={inputStyles}
                />
                <Input
                  name=""
                  label="Ano:"
                  value={quotation?.vehicle?.year ?? ''}
                  disabled
                  style={inputStyles}
                />
              </li>
              <li>
                <Input
                  name=""
                  label="Veículo:"
                  value={quotation?.vehicle?.name ?? ''}
                  disabled
                  style={inputStyles}
                />
              </li>
              <li>
                <Input
                  name=""
                  label="Chassi:"
                  value={quotation?.vehicle?.chassi ?? ''}
                  disabled
                  style={inputStyles}
                />
              </li>
              <li>
                <Textarea
                  name=""
                  label="Observações:"
                  value={quotation?.comment ?? ''}
                  disabled
                  style={{
                    border: 'none',
                    fontWeight: '500',
                    color: '#000000',
                  }}
                />
              </li>
              {quotation?.banners && quotation.banners.length > 0 && (
                <li className={styles['images-container']}>
                  <HorizontalBannerList
                    bannerList={quotation.banners}
                    label="Imagens Anexadas:"
                  />
                </li>
              )}
            </ul>
            <Button
              variant="default"
              theme="delete"
              handleClick={() => handleFinishModal('open')}
            >
              Finalizar cotação
            </Button>
          </>
        )}
      </div>
    </>
  );
};
