import { useCallback, useEffect, useState } from 'react';
import { getMyAnswersController } from '../../controller';
import { AnswerQuotation } from '../../../../../../@types/interface';
import { useAppSelector } from '../../../../../../hooks/useAppSelector';

const LIMIT = 80;

export const useApprovedQuotations = () => {
  const { notApprovedQuotationList } = useAppSelector(
    state => state.answerQuotationReducer,
  );

  const [page, setPage] = useState(1);
  const [notApprovedQuotations, setNotApprovedQuotations] = useState<
    AnswerQuotation[]
  >(notApprovedQuotationList);
  const [isLoading, setIsLoading] = useState(false);
  const [noMoreData, setNoMoreData] = useState(false);
  const [selectedAnswerToView, setSelectedAnswerToView] =
    useState<AnswerQuotation | null>(null);
  const [isViewQuotationModalOpen, setIsViewQuotationModalOpen] =
    useState(false);

  const fetchApprovedAnsweredQuotations = useCallback(
    async (pageRequest: number) => {
      setIsLoading(true);
      if (!noMoreData) {
        const res = await getMyAnswersController({
          limit: LIMIT,
          page: pageRequest,
          option: 'not_confirmed',
        });

        if (res && res.length > 0) {
          setNotApprovedQuotations(previous => {
            const newAnswers = res.filter(
              answer =>
                !previous.some(
                  existingQuotation =>
                    answer.id_answer_quotation ===
                    existingQuotation.id_answer_quotation,
                ),
            );
            return [...previous, ...newAnswers];
          });
        } else {
          setNoMoreData(true);
        }
      }
      setIsLoading(false);
    },
    [noMoreData],
  );

  const loadMoreData = useCallback(
    (previousPage = 1) => {
      if (!noMoreData) {
        fetchApprovedAnsweredQuotations(previousPage);
        setPage(previousPage + 1);
      }
    },
    [fetchApprovedAnsweredQuotations, noMoreData],
  );

  useEffect(() => {
    loadMoreData();
  }, [loadMoreData]);

  useEffect(() => {
    if (notApprovedQuotationList) {
      setNotApprovedQuotations(notApprovedQuotationList);
    }
  }, [notApprovedQuotationList]);

  const handleRowClick = (answer: AnswerQuotation) => {
    if (
      !selectedAnswerToView ||
      selectedAnswerToView.quotation_id !== answer.quotation_id
    ) {
      setSelectedAnswerToView(answer);
    } else {
      setSelectedAnswerToView(null);
    }
  };

  return {
    page,
    loadMoreData,
    fetchApprovedAnsweredQuotations,
    selectedAnswerToView,
    setSelectedAnswerToView,
    isLoading,
    notApprovedQuotations,
    isViewQuotationModalOpen,
    setIsViewQuotationModalOpen,
    handleRowClick,
  };
};
