import React from 'react';
import { Circle } from '@phosphor-icons/react';
import styles from './top-menu-button.module.css';

interface Props {
  children: React.ReactNode;
  isSelected?: boolean;
  hasNotification?: boolean;
  onClick: () => void;
}

export const TopMenuButton = ({
  children,
  isSelected = false,
  hasNotification = false,
  onClick,
}: Props) => {
  return (
    <button
      type="button"
      className={`${styles['top-menu--button']} ${
        isSelected ? styles.selected : ''
      }`}
      onClick={onClick}
    >
      {children}
      {hasNotification && (
        <Circle size={18} weight="fill" color="var(--error)" />
      )}
    </button>
  );
};
