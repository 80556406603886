import { ClockClockwise } from 'phosphor-react';
import { ListTable } from '../../../../../../shared/ListTable';
import { Quotation } from '../../../../../../@types/interface';

import { useQuotation } from '../../hooks/useQuotation';
import { CountDownTimer } from '../../../../../../shared/CountDownTimer';
import { useResponsive } from '../../../../../../hooks/useResponsive';
import { Button } from '../../../../../../shared/Button';

interface Props {
  quotation: Quotation;
  handleView: () => void;
  handleFinish: () => void;
  handleEdit: () => void;
  onRowClick: () => void;
  onCountDownClick: () => void;
  handleAddCompany: () => void;
  isSelected: boolean;
}

export const CreatedQuotesRow = ({
  quotation,
  handleView,
  handleFinish,
  handleEdit,
  onRowClick,
  onCountDownClick,
  isSelected,
  handleAddCompany,
}: Props) => {
  const { handleShowDate } = useQuotation();
  const { sizeMobile } = useResponsive();

  return (
    <>
      <ListTable.Row
        style={{
          gridTemplateColumns: !sizeMobile
            ? '1.5rem 1fr 2.5fr 1fr 1fr'
            : '1.5rem 1fr 2.5fr 1fr',
        }}
        selected={isSelected}
        variant="caret"
        handleClick={onRowClick}
      >
        <ListTable.RowItem
          style={{
            display: 'flex',
            paddingLeft: '2rem',
          }}
        >
          {quotation.QUOTATION}
        </ListTable.RowItem>
        {/* {!sizeMobile && (
          <ListTable.RowItem>
            <Button variant="unstyled" handleClick={onCountDownClick}>
              <CountDownTimer
                expirationHours={quotation?.hours ?? 0}
                initialDate={
                  quotation?.created_at
                    ? new Date(quotation?.created_at)
                    : new Date()
                }
              />
            </Button>
          </ListTable.RowItem>
        )} */}
        <ListTable.RowItem>
          {handleShowDate(quotation.created_at)}
        </ListTable.RowItem>
        <ListTable.RowItem>
          {quotation?.vehicle?.license_plate ?? '----'}
        </ListTable.RowItem>
      </ListTable.Row>
      {isSelected && (
        <ListTable.SubRow>
          <Button handleClick={handleAddCompany}>Adicionar Autopeças</Button>
          {/* {sizeMobile && (
            <Button variant="unstyled" handleClick={onCountDownClick}>
              <CountDownTimer
                expirationHours={quotation?.hours ?? 0}
                initialDate={quotation?.created_at ?? new Date()}
              />
            </Button>
          )} */}
          {/* <Button
            handleClick={onCountDownClick}
            style={{
              textAlign: 'center',
            }}
          >
            <ClockClockwise size={20} />
            Aumentar tempo
          </Button> */}
          <ListTable.ViewButton onClick={handleView} />

          <ListTable.EditButton onClick={handleEdit} text="Refazer" />
          <ListTable.DeleteButton onClick={handleFinish} text="Finalizar" />
        </ListTable.SubRow>
      )}
    </>
  );
};
