import { useRef } from 'react';
import { PageLayout } from '../../../../../../../shared/PageLayout';
import styles from './answer-quotation.module.css';
import { QuoteDescription } from './components/QuoteDescription';
import { AnswersList } from './components/AnswersList';
import { useAnswerQuotation } from './useAnswerQuotation';
import { AnswerFeedbackModal } from '../AnswerFeedbackModal';
import { AnswerQuotation as AnswerQuotationResponse } from '../../../../../../../@types/interface';
import { Overlay } from '../../../../../../../shared/Overlay';
import { ConfirmAnswerModal } from '../ConfirmAnswerModal';
import { ErrorModal } from '../ErrorModal';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  answer: AnswerQuotationResponse | null;
  localAnswerId?: string;
}

export const AnswerQuotation = ({
  isOpen,
  onClose,
  answer = null,
  localAnswerId = '',
}: Props) => {
  const overlayRef = useRef<HTMLDivElement>(null);

  const {
    control,
    reset,
    status,
    handleResetStatus,
    isFeedbackModalOpen,
    closeErrorModal,
    errorModalMessage,
    formQuotationItems,
    selectedAnswer,
    isConfirmModalOpen,
    handleConfirmModal,
    toggleModal,
    handleCloseModal,
    handleWhatsappMessage,
    addSubItem,
    removeSubItem,
    handleCloseErrorModal,
    trigger,
    handleAnswerWatchState,
    isBlockedToAnswer,
  } = useAnswerQuotation({ answer, isOpen, localAnswerId, onClose });
  const container = overlayRef.current || document.body;

  const handleModalNavigation = async () => {
    if (!isBlockedToAnswer) {
      await handleAnswerWatchState(
        selectedAnswer?.id_answer_quotation ?? '',
        'off',
      );
    }
    onClose();
    handleCloseModal();
  };

  return isOpen ? (
    <>
      <ConfirmAnswerModal
        isOpen={isConfirmModalOpen}
        onClose={() => toggleModal('close')}
        container={container}
        onCancel={() => toggleModal('close')}
        onConfirm={() => {
          handleConfirmModal();
        }}
      />
      <ErrorModal
        container={container}
        isOpen={errorModalMessage.trim().length !== 0}
        onClose={closeErrorModal}
        message={errorModalMessage}
      />
      <AnswerFeedbackModal
        container={container}
        isOpen={isFeedbackModalOpen}
        status={status}
        onClose={async () => {
          if (status === 'error') {
            handleCloseErrorModal();
          } else {
            if (!isBlockedToAnswer) {
              await handleAnswerWatchState(
                selectedAnswer?.id_answer_quotation ?? '',
                'off',
              );
            }
            handleResetStatus();
            onClose();
          }
        }}
      />
      <Overlay ref={overlayRef}>
        <PageLayout.Root
          title="Responder cotação"
          sectionStyle={{
            background: 'var(--white)',
          }}
          handleNavigation={handleModalNavigation}
        >
          <div className={styles.content}>
            <div>
              <QuoteDescription answer={selectedAnswer} />
              {isBlockedToAnswer ? (
                <h1>Essa Cotação já está sendo respondida por alguém</h1>
              ) : (
                <AnswersList
                  answer={selectedAnswer}
                  control={control}
                  reset={reset}
                  handleAnswer={async () => {
                    const isValid = await trigger();
                    if (isValid) toggleModal('open');
                  }}
                  formQuotationItems={formQuotationItems}
                  handleWhatsappMessage={handleWhatsappMessage}
                  addSubItem={addSubItem}
                  removeSubItem={removeSubItem}
                  onClose={onClose}
                />
              )}
            </div>
          </div>
        </PageLayout.Root>
      </Overlay>
    </>
  ) : null;
};
