import { useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import { Input } from '../../../../../../../../../shared/Input';
import { FormValues } from '../../useQuotationForm';
import { maskLicensePlate } from '../../../../../../../../../services/helpers/mask';
import { fetchVehicleDataByLicensePlateController } from '../../../../../../../../../services/dataFetchers/VehicleDataFetcher/VehicleDataFetcher';
import styles from './vehicle-info.module.css';
import { VehicleResponse } from '../../../../../../../../../services/dataFetchers/VehicleDataFetcher/model';

interface Props {
  control: Control<FormValues, any>;
  handleVehicleFields: (vehicle: VehicleResponse) => void;
}

export const VehicleInfo = ({ control, handleVehicleFields }: Props) => {
  const [isLoadingVehicle, setIsLoadingVehicle] = useState(false);

  const handleVehicleSearch = async (plate: string) => {
    setIsLoadingVehicle(true);
    const res = await fetchVehicleDataByLicensePlateController(plate);
    if (res) {
      handleVehicleFields(res);
    }
    setIsLoadingVehicle(false);
  };

  return (
    <div className={styles['vehicle-info']}>
      <div className={styles.row}>
        <Controller
          name="plate"
          control={control}
          render={({ field: { name, onChange, value } }) => (
            <Input
              name={name}
              label="Placa:"
              value={maskLicensePlate(value || '')}
              isLoading={isLoadingVehicle}
              maxLength={7}
              handleChange={e => {
                const formattedPlate = maskLicensePlate(e.target.value);
                if (formattedPlate.length === 7) {
                  handleVehicleSearch(formattedPlate);
                }
                onChange(e);
              }}
            />
          )}
        />
        <Controller
          name="year"
          control={control}
          render={({ field: { name, onChange, value } }) => (
            <Input
              name={name}
              label="Ano:"
              value={value}
              handleChange={onChange}
              disabled
            />
          )}
        />
      </div>
      <div className={styles.row}>
        <Controller
          name="vehicle"
          control={control}
          render={({ field: { name, onChange, value } }) => (
            <Input
              name={name}
              label="Veículo:"
              value={value}
              handleChange={onChange}
              disabled
            />
          )}
        />
      </div>
      <div className={styles.row}>
        <Controller
          name="chassi"
          control={control}
          render={({ field: { name, onChange, value } }) => (
            <Input
              name={name}
              label="Chassi:"
              value={value}
              handleChange={onChange}
              disabled
            />
          )}
        />
        {/* <Controller
          name="hours"
          control={control}
          render={({ field: { name, onChange, value } }) => (
            <Input
              name={name}
              label="Tempo de resposta:"
              placeholder="8 horas"
              value={value}
              handleChange={e => {
                const newValue = e.target.value;
                if (/^\d*$/.test(newValue)) {
                  onChange(e);
                }
              }}
              pattern="\d*"
            />
          )}
        /> */}
        <Controller
          name="paymentMethod"
          control={control}
          render={({ field: { name, onChange, value } }) => (
            <Input
              name={name}
              label="Forma de Pagamento:"
              value={value}
              handleChange={onChange}
              type="select"
              options={[
                {
                  name: 'Escolha uma opção',
                  value: '',
                },
                {
                  name: 'A vista',
                  value: 'A vista',
                },
                {
                  name: 'A prazo',
                  value: 'A prazo',
                },
                {
                  name: 'Cartão',
                  value: 'Cartao',
                },
              ]}
              style={{
                fontWeight: '500',
                fontSize: '1.125rem',
              }}
            />
          )}
        />
      </div>
    </div>
  );
};
