// use this file for globals variables in this project

export const QUOTATION = {
  MAX_ANSWER_SUBITEMS: 3,
  REQUEST_LIMIT: 40,
  TIME_UPDATE: 15 * 1000,
};

export const AUTH = {
  TOKEN_REFRESH_INTERVAL: 15 * 60 * 1000,
};
