/* eslint-disable no-alert */
/* eslint-disable prettier/prettier */
import { useEffect, useState } from 'react';
import { XSquare } from 'phosphor-react';
import { useDispatch } from 'react-redux';
import { Modal } from '../../Components/modal/index';
import styles from './page-register.module.css';
import autocenterLogo from '../../../../../assets/img/Autocenter-logo-marca-new-design.svg';
import { useAppSelector } from '../../../../../hooks/useAppSelector';
import logo from '../../../../../assets/logo_icone.svg';
import pattern from '../../../../../assets/img/modal-pattern.svg';
import {
  maskCpfCnpj,
  textOnly,
  maskPhone,
} from '../../../../../services/helpers/mask';
import { setOpen } from '../../../../../features/modalLanginPage/openModal';
import message from '../../../../../utils/message';

export const messageWrong = (status: number) => {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  let message = '';
  if (status === 401) {
    message = 'O dominio está impedindo de enviar o formulário';
  } else if (status === 404) {
    message = 'Formulário não encontrado';
  } else {
    message = 'Erro inesperado';
  }
  return message;
};

const Register = () => {
  const { show } = useAppSelector(state => state.openModalRegister);
  const [modalSucess, setModalSucess] = useState(false);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [cpf, setCpf] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      setName('');
      setPhone('');
      setEmail('');
      setCpf('');
    };
  }, []);

  const onSubmit = async (e: any) => {
    e.preventDefault();

    if (name.length < 2) {
      return alert(`Parece que o campo nome não foi preenchido corretamente!`);
    }
    if (phone.length <= 10) {
      return alert(
        `Parece que o campo telefone não foi preenchido corretamente!`,
      );
    }

    fetch('https://api.sheetmonkey.io/form/w4Yoq1PGmqm7H6Z6mNjPeq', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name,
        phone,
        email,
        cpf: maskCpfCnpj(cpf),
        message: '',
        date: 'x-sheetmonkey-current-date-time',
        ip: 'x-sheetmonkey-ip-address',
      }),
    })
      .then(res => {
        if (res.status === 200) {
          dispatch(setOpen({ show: false }));

          return setModalSucess(true);
        }
        message(messageWrong(res.status), 'error');
      })
      .catch(error => {
        return message(error.message, 'error');
      });
  };

  return (
    <>
      <div style={{ display: `${show ? 'block' : 'none'} ` }}>
        <Modal>
          <div className={styles.logo}>
            <img src={autocenterLogo} alt="logo" />
          </div>
          <div className={styles.container}>
            <div className={styles.title}>
              <h1>Teste Gratuitamente</h1>
              <p>
                Falta pouco para você transformar a organização da sua oficina
              </p>
            </div>
            <form onSubmit={onSubmit} className={styles.form}>
              <div>
                <h2>
                  Nome <span>*</span>
                </h2>
                <input
                  type="text"
                  placeholder="Nome"
                  name="data[name]"
                  value={name}
                  onChange={e => setName(textOnly(e.target.value))}
                  className={styles.formInput}
                />
              </div>

              <div>
                <h2>
                  Telefone <span>*</span>
                </h2>
                <input
                  type="text"
                  placeholder="(XX) XXXXX-XXXX"
                  name="data[phone]"
                  value={phone}
                  onChange={e => setPhone(maskPhone(e.target.value))}
                  className={styles.formInput}
                />
              </div>

              <div>
                <h2>Email</h2>
                <input
                  type="email"
                  placeholder="minhaconta@email.com"
                  name="data[email]"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  className={styles.formInput}
                />
              </div>

              <div>
                <h2>CPF/CNPJ</h2>
                <input
                  type="text"
                  placeholder="CPF/CNPJ"
                  name="data[cpf]"
                  value={cpf}
                  onChange={e => setCpf(maskCpfCnpj(e.target.value))}
                  className={styles.formInput}
                />
              </div>

              <div>
                <button type="submit" className={styles.formBtn}>
                  COMEÇAR MEU TESTE GRÁTIS
                </button>
              </div>
            </form>
          </div>
        </Modal>
      </div>
      <div
        className={styles.overlayModalSucess}
        style={{ display: `${modalSucess ? 'flex' : 'none'} ` }}
      >
        <div className={styles.contentModalSucess}>
          <div className={styles.header}>
            <img src={logo} alt="Logo" />
            <img src={pattern} alt="fundo" className={styles.pattern} />
            <h2>Sucesso!</h2>
            <button type="button" onClick={() => setModalSucess(false)}>
              <XSquare size={30} color="#E53835" />
            </button>
          </div>
          <div className={styles.body}>
            <p>Recebemos suas informações. Em breve entraremos em contato.</p>
            <button type="button" onClick={() => setModalSucess(false)}>
              <a
                href="https://oficinas.autocenterapp.com/page-login?visitor=true"
                target="_blank"
                rel="noreferrer"
              >
                Acessar como visitante
              </a>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
