import styles from './Footer.module.css';

import logoImage_white from '../../../../../assets/img/LandingPage/logoImage_white.svg';
import logoText_white from '../../../../../assets/img/LandingPage/logoText_white.svg';
import iconInstagram from '../../../../../assets/img/LandingPage/icopInstagram.svg';
import iconWhatsapp from '../../../../../assets/img/LandingPage/iconWhatsapp.svg';
import iconMail from '../../../../../assets/img/LandingPage/iconMail.svg';

export const FooterLandingPage = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerInfo}>
        <div className={styles.logo_footer}>
          <img
            className={styles.footer_logoImage}
            src={logoImage_white}
            alt="logoImage"
          />
          <img
            className={styles.footer_logoText}
            src={logoText_white}
            alt="Autocenter"
          />
        </div>
        <p>Autocenter @ 2023</p>
        <p>Todos so direitos reservados</p>
      </div>
      <div className={styles.nossosValores}>
        <strong>Nossos Valores</strong>
        <ul>
          <li>Inovação</li>
          <li>Qualidade</li>
          <li>Reponsabilidade Social</li>
          <li>Transparência</li>
        </ul>
      </div>
      <div className={styles.contato}>
        <strong>Contato</strong>
        <div className={styles.buttonsContatos}>
          <a
            href="https://www.instagram.com/appautocenter/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={iconInstagram} alt="" />
          </a>
          <a
            href="https://wa.me/5587991080605"
            target="_blank"
            rel="noreferrer"
          >
            <img src={iconWhatsapp} alt="" />
          </a>
          <a
            href="mailto:contato@autocenter.app"
            target="_blank"
            rel="noreferrer"
          >
            <img src={iconMail} alt="" />
          </a>
        </div>
      </div>
    </footer>
  );
};
