import { Control, Controller } from 'react-hook-form';
import { Trash } from '@phosphor-icons/react';
import { ProductTable } from '../../../../../../../../../shared/ProductTable';
import { InteractableSpan } from '../../../../../../../../../shared/InteractableSpan';
import { FormValues } from '../../useQuotationForm';
import { numberOnly } from '../../../../../../../../../services/helpers/mask';
import styles from './product-table-row.module.css';
import { Button } from '../../../../../../../../../shared/Button';

interface Props {
  index: number;
  control: Control<FormValues, any>;
  onRemove: () => void;
}

export const ProductTableRow = ({ index, control, onRemove }: Props) => {
  return (
    <div className={styles.wrapProductTableRow}>
      <ProductTable.Row style={{ gridTemplateColumns: '0.25fr 1fr .5fr 1fr' }}>
        <div className={styles.actions}>
          <span>{index + 1}</span>
        </div>
        <Controller
          name={`quotes.${index}.description`}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <InteractableSpan
              placeholder="Descrição"
              isSelected
              value={value}
              handleChange={onChange}
              isInvalid={!!error}
            />
          )}
        />
        <Controller
          name={`quotes.${index}.quantity`}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <InteractableSpan
              placeholder="Quantidade"
              isSelected
              value={value}
              handleChange={quantity =>
                onChange(numberOnly(quantity.target.value.toString()))
              }
              isInvalid={!!error}
            />
          )}
        />
        <div className={styles['last-row']}>
          <Controller
            name={`quotes.${index}.reference`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <InteractableSpan
                placeholder="Referência"
                alignment="center"
                isSelected
                value={value.toUpperCase()}
                handleChange={data => {
                  const text = data.target.value.toUpperCase();
                  onChange(text);
                }}
              />
            )}
          />

          <Button
            variant="icon"
            handleClick={onRemove}
            icon={<Trash size={24} />}
          />
        </div>
      </ProductTable.Row>
    </div>
  );
};
