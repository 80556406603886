import { ArrowFatLeft, ArrowLeft, List } from '@phosphor-icons/react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../Button';
import styles from './header-component.module.css';
import { COLORS } from '../../styles/colors';
import logo from '../../assets/logo_autocenter.svg';
import pattern from '../../assets/img/pattern-1.svg';

interface Props {
  title: string;
  handleMenuClick?: () => void;
  handleNavigation?: () => void;
}

export const HeaderComponent = ({
  title,
  handleMenuClick,
  handleNavigation,
}: Props) => {
  const navigate = useNavigate();

  const handleBackButtonClick = () => {
    if (handleNavigation) {
      handleNavigation();
    } else {
      navigate('/home');
      sessionStorage.removeItem('auth');
    }
  };

  return (
    <header className={styles.header}>
      <img src={logo} alt="Logo" className={styles.logo} />
      <div className={styles.content}>
        <div className={styles.desktop}>
          <Button
            handleClick={handleBackButtonClick}
            variant="register"
            icon={<ArrowFatLeft size={18} weight="fill" color={COLORS.WHITE} />}
            style={{
              minWidth: '80px',
            }}
          >
            voltar
          </Button>
        </div>
        <h1 className={styles.title}>{title}</h1>
        <img src={pattern} alt="textura" className={styles.pattern} />
      </div>
      <div className={styles['back-btn']}>
        <Button
          handleClick={handleBackButtonClick}
          style={{
            borderRadius: '4px',
            padding: '.5rem',
          }}
        >
          <ArrowLeft size={24} weight="bold" color={COLORS.WHITE} />
        </Button>
      </div>
      {handleMenuClick && (
        <div className={styles.menu}>
          <Button
            handleClick={handleMenuClick}
            icon={<List size={32} weight="bold" />}
            variant="icon"
          />
        </div>
      )}
    </header>
  );
};
