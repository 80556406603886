export const COLORS = {
  GREEN: '#6BB56A',
  WHITE: '#FFFFFF',
  RED: '#F54B4F',
  YELLOW: '#FCCA36',
  DARKBLUE: '#0B121C',
  BLUE: '#51A6E5',
  BLACK: '#000000',
  LIGHTGRAY: '#F0F0F0',
  DARKGRAY: '#1e1e1e',
  CHARCOAL: '#4b4b4b',
  NEWGREEN: '#88b787',
  NEWNOTIFICATION: '#007a37',
};
