import styles from './answer-list-loading.module.css';
import { Loading } from '../../../../../../../../../shared/Loading';

export const AnswerListLoading = () => {
  return (
    <div>
      <div className={styles.header}>
        <Loading />
      </div>
      <div className={styles.body}>
        <Loading
          style={{
            height: '8rem',
          }}
        />
        <Loading
          style={{
            height: '8rem',
          }}
        />
        <Loading
          style={{
            height: '8rem',
          }}
        />
      </div>
    </div>
  );
};
