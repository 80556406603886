import { CSSProperties } from 'react';
import styles from './circle.module.css';

interface Props {
  variant?: 'default' | 'notification' | 'modified' | 'disabled';
  style?: CSSProperties;
  animation?: 'static' | 'pulse';
}

export const Circle = ({
  style,
  variant = 'default',
  animation = 'static',
}: Props) => {
  return (
    <div
      style={style}
      className={`${styles.circle} ${styles[variant]} ${styles[animation]}`}
    />
  );
};
