import { FileText } from 'phosphor-react';
import { Checkbox } from '../Checkbox';
import { BrandInfo } from './components/BrandInfo';
import { ValueBox } from './components/ValueBox';
import styles from './item-brand.module.css';

export type ItemBrandType =
  | 'finished'
  | 'answered'
  | 'toAnswer'
  | 'workshopVisualization'
  | 'workshopAnswer'
  | 'empty';

export interface ItemBrandProps {
  brand: string;
  reference: string;
  unitValue: string;
  quantity: string;
}

interface Props extends ItemBrandProps {
  type: ItemBrandType;
  responsive: 'web' | 'mobile';
}

const renderFinished = ({
  brand,
  quantity,
  reference,
  unitValue,
  responsive,
}: Omit<Props, 'type'>) => {
  return (
    <div
      className={`${styles['item-brand']} ${styles.finished} ${styles[responsive]}`}
    >
      <BrandInfo responsive={responsive} brand={brand} reference={reference} />

      <div className={styles['box-right']}>
        <ValueBox responsive={responsive} value={unitValue} />
        <div className={styles['order-box']}>
          <b>Qtd. Pedida</b>
          <span className={styles.orders}>{quantity}</span>
        </div>
      </div>
    </div>
  );
};
const renderAnswered = ({
  brand,
  quantity,
  reference,
  unitValue,
  responsive,
}: Omit<Props, 'type'>) => {
  return (
    <div
      className={`${styles['item-brand']} ${styles.answered} ${styles[responsive]}`}
    >
      <BrandInfo responsive={responsive} brand={brand} reference={reference} />

      <div className={styles['box-right']}>
        <ValueBox responsive={responsive} value={unitValue} />

        <div className={styles['answer-box']}>
          <b>Resposta</b>
          <span className={styles.answer}>{quantity}</span>
        </div>
      </div>
    </div>
  );
};
const renderToAnswer = ({
  brand,
  quantity,
  reference,
  unitValue,
  responsive,
}: Omit<Props, 'type'>) => {
  return (
    <div
      className={`${styles['item-brand']} ${styles['to-answer']} ${styles[responsive]}`}
    >
      <BrandInfo responsive={responsive} brand={brand} reference={reference} />

      <div className={styles['box-right']}>
        <ValueBox responsive={responsive} value={unitValue} />

        <div className={styles['answer-box']}>
          <div className={styles['input-box']}>
            <Checkbox name="input-box" theme="green" />
            <input type="text" className={styles.input} />
          </div>
          <div className={styles['available-quantity']}>
            <span>
              Qtd. disponível: <b>{quantity}</b>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const renderWorkshopVisualization = ({
  brand,
  quantity,
  reference,
  unitValue,
  responsive,
}: Omit<Props, 'type'>) => {
  return (
    <div
      className={`${styles['item-brand']} ${styles['workshop-visualization']} ${styles[responsive]}`}
    >
      <BrandInfo responsive={responsive} brand={brand} reference={reference} />

      <div className={styles['box-right']}>
        <ValueBox responsive={responsive} value={unitValue} />

        <div className={styles['order-box']}>
          <b>Qtd. Disponível</b>
          <span className={styles.orders}>{quantity}</span>
        </div>
      </div>
    </div>
  );
};
const renderEmpty = ({ responsive }: Omit<Props, 'type'>) => {
  return (
    <div
      className={`${styles['item-brand']}  ${styles.empty}  ${styles[responsive]}`}
    >
      <div className={styles.info}>
        <FileText size={32} color="var(--grey)" />
        <span>Sem Oferta</span>
      </div>
    </div>
  );
};

export const ItemBrand = ({ type, ...props }: Props) => {
  if (type === 'finished') return renderFinished(props);
  if (type === 'answered') return renderAnswered(props);
  if (type === 'toAnswer') return renderToAnswer(props);
  if (type === 'workshopVisualization')
    return renderWorkshopVisualization(props);
  if (type === 'empty') return renderEmpty(props);
  return null;
};
