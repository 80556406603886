import { useCallback, useEffect, useState } from 'react';
import {
  getMyAnswersController,
  updateAnswerQuotationWatchStateController,
} from '../../controller';
import { AnswerQuotation } from '../../../../../../@types/interface';
import { useAppSelector } from '../../../../../../hooks/useAppSelector';
import { UpdateAnswerQuotationWatchStateInput } from '../../interface';

const LIMIT = 80;

export const useNotAnsweredQuotations = () => {
  const { notAnsweredQuotationList } = useAppSelector(
    state => state.answerQuotationReducer,
  );

  const [page, setPage] = useState(1);
  const [notAnsweredQuotations, setNotAnsweredQuotations] = useState<
    AnswerQuotation[]
  >(notAnsweredQuotationList);
  const [showBlockedQuotationMessage, setShowBlockedQuotationMessage] =
    useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [noMoreData, setNoMoreData] = useState(false);
  const [selectedAnswerToView, setSelectedAnswerToView] =
    useState<AnswerQuotation | null>(null);
  const [isAnswerQuotationModalOpen, setIsAnswerQuotationModalOpen] =
    useState(false);

  const [isViewQuotationModalOpen, setIsViewQuotationModalOpen] =
    useState(false);

  const fetchNotAnsweredQuotations = useCallback(
    async (pageRequest: number) => {
      setIsLoading(true);
      if (!noMoreData) {
        const res = await getMyAnswersController({
          limit: LIMIT,
          page: pageRequest,
          option: 'recieved',
        });

        if (res && res.length > 0) {
          setNotAnsweredQuotations(previous => {
            const newAnswers = res.filter(
              answer =>
                !previous.some(
                  existingQuotation =>
                    answer.id_answer_quotation ===
                    existingQuotation.id_answer_quotation,
                ),
            );
            return [...previous, ...newAnswers];
          });
        } else {
          setNoMoreData(true);
        }
      }
      setIsLoading(false);
    },
    [noMoreData],
  );

  const loadMoreData = useCallback(
    (previousPage = 1) => {
      if (!noMoreData) {
        fetchNotAnsweredQuotations(previousPage);
        setPage(previousPage + 1);
      }
    },
    [fetchNotAnsweredQuotations, noMoreData],
  );

  useEffect(() => {
    loadMoreData();
  }, [loadMoreData]);
  useEffect(() => {
    if (notAnsweredQuotationList) {
      setNotAnsweredQuotations(notAnsweredQuotationList);
    }
  }, [notAnsweredQuotationList]);

  const handleRowClick = (answer: AnswerQuotation) => {
    if (
      !selectedAnswerToView ||
      selectedAnswerToView.quotation_id !== answer.quotation_id
    ) {
      setSelectedAnswerToView(answer);
    } else {
      setSelectedAnswerToView(null);
    }
  };
  const handleShowBlockedQuotationMessage = (option: 'open' | 'close') => {
    if (option === 'open') setShowBlockedQuotationMessage(true);
    else setShowBlockedQuotationMessage(false);
  };

  // const handleWatchState = useCallback(
  //   async (props: UpdateAnswerQuotationWatchStateInput) => {
  //     const res = await updateAnswerQuotationWatchStateController(props);
  //     return res;
  //   },
  //   [],
  // );

  return {
    page,
    loadMoreData,
    fetchNotAnsweredQuotations,
    selectedAnswerToView,
    setSelectedAnswerToView,
    isLoading,
    notAnsweredQuotations,
    isAnswerQuotationModalOpen,
    setIsAnswerQuotationModalOpen,
    handleRowClick,
    showBlockedQuotationMessage,
    handleShowBlockedQuotationMessage,
    isViewQuotationModalOpen,
    setIsViewQuotationModalOpen,
    // handleWatchState,
  };
};
