import React, { CSSProperties } from 'react';
import styles from './header.module.css';

interface Props {
  children: React.ReactNode;
  style?: CSSProperties;
}

export const Header = ({ children, style }: Props) => {
  return (
    <div className={styles.header} style={style}>
      {children}
    </div>
  );
};
