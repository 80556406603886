import styles from './approved-quotations.module.css';
import { ListTable } from '../../../../../../shared/ListTable';
import { ApprovedQuotationRow } from '../../components/ApprovedQuotationRow';
import AnsweredQuotationsIcon from '../../../../../../assets/icons/answered-quotations.svg';
import { useApprovedQuotations } from './useApprovedQuotations';
import { ApproveQuotationModal } from '../../components/modals/ApproveQuotationModal';
import { sendWhatsappMessage } from '../../../../../../utils/sendWhatsappMessage';
import { useResponsive } from '../../../../../../hooks/useResponsive';
import { FilterComponent } from '../../../../../../shared/FilterComponent';
import { useFilteredAnswers } from '../../hooks/useFilteredAnswers';
import { GetMyAnswerFilters } from '../../interface';

export const ApprovedQuotations = () => {
  const {
    notApprovedQuotations,
    isLoading,
    loadMoreData,
    page,
    selectedAnswerToView,
    setSelectedAnswerToView,
    isViewQuotationModalOpen,
    setIsViewQuotationModalOpen,
    handleRowClick,
  } = useApprovedQuotations();
  const { sizeMobile } = useResponsive();

  const {
    filterType,
    filterValue,
    handleDateSearch,
    handleSearch,
    searchedAnswers,
    setFilterType,
  } = useFilteredAnswers({
    option: 'not_confirmed',
  });

  return (
    <>
      <ApproveQuotationModal
        isOpen={isViewQuotationModalOpen}
        onClose={() => {
          setSelectedAnswerToView(null);
          setIsViewQuotationModalOpen(false);
        }}
        quotationAnswer={selectedAnswerToView}
      />
      <div className={styles.heading}>
        <div className={styles.describe}>
          <img src={AnsweredQuotationsIcon} alt="responder cotações" />
          <p>Pedidos aguardando confirmação</p>
        </div>
        <div className={styles.actions}>
          <FilterComponent
            handleDateSearch={handleDateSearch}
            handleSearch={handleSearch}
            handleFilterChange={(filter: string) =>
              setFilterType(filter as GetMyAnswerFilters)
            }
            defaultValue="code"
            variant={filterType === 'date_recieved' ? 'date' : 'searchbar'}
            onlyNumber={filterType === 'code'}
            options={[
              {
                label: 'Número',
                value: 'code',
              },
              {
                label: 'Data',
                value: 'date_recieved',
              },
              {
                label: 'Placa',
                value: 'plate',
              },
              {
                label: 'Origem',
                value: 'origin',
              },
            ]}
          />
        </div>
      </div>
      <ListTable.Root
        isLoading={isLoading}
        isEmpty={!isLoading && notApprovedQuotations.length === 0}
        notFound={filterValue.trim().length > 0 && searchedAnswers.length === 0}
        message="Nenhuma Cotação Encontrada"
      >
        <div className={styles.container}>
          <ListTable.Head
            style={{
              gridTemplateColumns: sizeMobile
                ? 'repeat(3, 1.5fr) 2.5fr'
                : 'repeat(3, 1fr) 2fr',
            }}
          >
            <ListTable.HeadItem>
              {sizeMobile ? 'N° de cotação' : 'Número de cotação'}
            </ListTable.HeadItem>
            <ListTable.HeadItem>Data de Criação</ListTable.HeadItem>
            <ListTable.HeadItem>Data da Aprovação</ListTable.HeadItem>
            <ListTable.HeadItem>Origem</ListTable.HeadItem>
          </ListTable.Head>
          <ListTable.Body onScroll={() => loadMoreData(page)}>
            {/* if user has searched something it shows the response */}
            {(filterValue.trim().length > 0
              ? searchedAnswers
              : notApprovedQuotations
            ).map(answer => (
              <ApprovedQuotationRow
                key={answer.id_answer_quotation}
                answer={answer}
                handleView={() => setIsViewQuotationModalOpen(true)}
                onRowClick={() => handleRowClick(answer)}
                handleWhatsapp={() =>
                  sendWhatsappMessage(
                    `Olá *${
                      answer?.quotation?.workshop?.fantasy_name ?? ''
                    }* , gostaria de mais informações para envio da cotação *n° ${
                      answer?.quotation?.QUOTATION ?? ''
                    }*`,
                    answer?.quotation?.workshop?.whatsapp ?? '',
                  )
                }
                isSelected={
                  !!selectedAnswerToView &&
                  selectedAnswerToView.quotation_id === answer.quotation_id
                }
              />
            ))}
          </ListTable.Body>
        </div>
      </ListTable.Root>
    </>
  );
};
