import axios from 'axios';
import api from '../../../../services/api/api';
import { UpdateUserPasswordInput } from './interfaces';

export const updateUserPassword = async (props: UpdateUserPasswordInput) => {
  const { data } = await api.put('/user/self', {
    ...props,
  });
  return data;
};
