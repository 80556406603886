/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { Navigate } from 'react-router-dom';
import { isAuthenticated } from '../services/api/auth';
import { PasswordHasChanged } from '../services/api/changePassword';

interface Props {
  children: React.ReactNode;
}

export const PrivateRoute = ({ children }: Props) => {
  if (
    !children ||
    typeof children === 'string' ||
    typeof children === 'number' ||
    typeof children === 'boolean'
  )
    return <></>;
  // if (PasswordHasChanged()) {
  //   return (
  //     <UserEdit messageToUpdate="Por favor, para prosseguir, mude a sua senha" />
  //   );
  // }
  const user = localStorage.getItem('@AutoCenter: user');
  if (!user) alert('faça login para prosseguir!');
  return isAuthenticated() ? <>{children}</> : <Navigate to="/" />;
};

export default PrivateRoute;
