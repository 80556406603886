import { User } from '../@types/User';

export const getWorkshopData = () => {
  const localUser: User = JSON.parse(
    localStorage.getItem('@AutoCenter: user') || '{}',
  );
  const workshop = localUser.agents[0].company;
  return workshop;
};
export const getUserData = () => {
  const localUser: User = JSON.parse(
    localStorage.getItem('@AutoCenter: user') || '{}',
  );
  return localUser;
};
