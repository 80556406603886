import { Controller, useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { Modal } from '../../../../../../../shared/Modal';
import { Textarea } from '../../../../../../../shared/Textarea';
import { Input } from '../../../../../../../shared/Input';
import styles from './view-finished-quotation.module.css';
import { ProductTable } from '../../../../../../../shared/ProductTable';
import { AnswerQuotation } from '../../../../../../../@types/interface';
import { useResponsive } from '../../../../../../../hooks/useResponsive';
import { ItemQuotationList } from '../../../../../../../shared/ItemQuotationList';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  answer: AnswerQuotation | null;
}

interface Quote {
  description: string;
  reference: string;
  quantity: string;
  value: string;
  availableQuantity: string;
}

export interface FormValues {
  plate?: string;
  vehicle?: string;
  chassi?: string;
  year?: string;
  model?: string;
  quotes: Quote[];
  observation?: string;
  paymentMethod: string;
}

export const ViewFinishedQuotationModal = ({
  isOpen,
  onClose,
  answer,
}: Props) => {
  const { sizeMobile } = useResponsive();
  const { control, reset } = useForm<FormValues>({
    defaultValues: {
      chassi: '',
      model: '',
      plate: '',
      vehicle: '',
      year: '',
      quotes: [
        {
          description: '',
          quantity: '',
          reference: '',
          availableQuantity: '',
          value: '',
        },
      ],
      observation: '',
      paymentMethod: '',
    },
  });

  useEffect(() => {
    if (answer && answer.quotation) {
      const { quotation } = answer;
      reset({
        chassi: quotation?.vehicle?.chassi ?? '',
        model: quotation?.vehicle?.model ?? '',
        plate: quotation?.vehicle?.license_plate ?? '',
        vehicle: quotation?.vehicle?.name ?? '',
        year: quotation?.vehicle?.year ?? '',
        quotes: quotation.items_quotation.map(item => ({
          description: item?.description ?? '',
          quantity: item?.quantity ?? '',
          reference: item?.reference ?? '',
          availableQuantity: '',
          value: '',
        })),
        observation: quotation?.comment ?? '',
        paymentMethod: quotation.type_payment ?? 'Não informado',
      });
    }
  }, [answer, reset]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={`Visualizar Cotação - N° ${answer?.quotation?.QUOTATION || ''}`}
      size={sizeMobile ? 'full' : 'large'}
    >
      <div className={styles['vehicle-info']}>
        <div className={styles.row}>
          <Controller
            name="plate"
            control={control}
            render={({ field: { name, onChange, value } }) => (
              <Input
                name={name}
                label="Placa:"
                value={value}
                handleChange={onChange}
                disabled
                style={{ fontWeight: '600', color: '#000000' }}
              />
            )}
          />
          <Controller
            name="vehicle"
            control={control}
            render={({ field: { name, onChange, value } }) => (
              <Input
                name={name}
                label="Veículo:"
                value={value}
                handleChange={onChange}
                disabled
                style={{ fontWeight: '600', color: '#000000' }}
              />
            )}
          />
          <Controller
            name="chassi"
            control={control}
            render={({ field: { name, onChange, value } }) => (
              <Input
                name={name}
                label="Chassi:"
                value={value}
                handleChange={onChange}
                disabled
                style={{ fontWeight: '600', color: '#000000' }}
              />
            )}
          />
        </div>
        <div className={styles.row}>
          <Controller
            name="year"
            control={control}
            render={({ field: { name, onChange, value } }) => (
              <Input
                name={name}
                label="Ano:"
                value={value}
                handleChange={onChange}
                disabled
                style={{ fontWeight: '600', color: '#000000' }}
              />
            )}
          />
          <Controller
            name="model"
            control={control}
            render={({ field: { name, onChange, value } }) => (
              <Input
                name={name}
                label="Modelo:"
                value={value}
                handleChange={onChange}
                disabled
                style={{ fontWeight: '600', color: '#000000' }}
              />
            )}
          />
        </div>
        <Controller
          name="paymentMethod"
          control={control}
          render={({ field: { name, onChange, value } }) => (
            <Input
              name={name}
              label="Forma de Pagamento:"
              value={value}
              handleChange={onChange}
              disabled
              style={{ fontWeight: '600', color: '#000000' }}
            />
          )}
        />
        <div className={styles.observation}>
          <Controller
            name="observation"
            control={control}
            render={({ field: { name, onChange, value } }) => (
              <Textarea
                name={name}
                handleChange={onChange}
                value={value}
                label="Observações:"
                disabled
                style={{
                  height: '5rem',
                  fontWeight: '500',
                  color: '#000000',
                }}
              />
            )}
          />
        </div>
      </div>
      <ProductTable.Root
        heading={
          <div>
            <h3 className={styles.label}>
              Fornecedor {answer?.workshop?.fantasy_name ?? ''}
            </h3>
            {answer?.agent && (
              <sub>
                <b>Vendedor: {answer?.agent?.user?.name ?? ''}</b>
              </sub>
            )}
          </div>
        }
      >
        <ul className={styles['table-header']}>
          <li>Item</li>
          <li>Descrição</li>
          <li>Qtd. Solicitada</li>
        </ul>
        <div className={styles.answer}>
          {answer &&
            answer.quotation &&
            answer.quotation.items_quotation.map((quotationItem, index) => {
              const correspondingAnswers = answer.items_answer_quotation.filter(
                answerItem => {
                  return (
                    answerItem.item_quotation_id ===
                    quotationItem.id_item_quotation
                  );
                },
              );

              return (
                <ItemQuotationList
                  index={`${index + 1}`}
                  name={quotationItem?.description ?? '----'}
                  quantity={quotationItem?.quantity ?? '----'}
                  responsive={sizeMobile ? 'mobile' : 'web'}
                  items={correspondingAnswers.map(item => ({
                    brand: item.brand ?? '',
                    reference: item.reference ?? '',
                    unitValue: item.total ? `${item.total}` : '----',
                    quantity: item.quantity_asked
                      ? `${item.quantity_asked}`
                      : '----',
                  }))}
                  type="itemView"
                  itemBrandType="finished"
                  notOffer={correspondingAnswers.length === 0}
                  key={quotationItem.id_item_quotation}
                />
              );
            })}
        </div>
      </ProductTable.Root>
    </Modal>
  );
};
