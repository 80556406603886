import styles from './view-item-quotation-list.module.css';
import { ProductTable } from '../../../../../../shared/ProductTable';
import { AnswerQuotation } from '../../../../../../@types/interface';
import { ItemQuotationList } from '../../../../../../shared/ItemQuotationList';
import { useResponsive } from '../../../../../../hooks/useResponsive';

interface Props {
  quotationAnswer: AnswerQuotation | null;
}

export const ViewItemQuotationList = ({ quotationAnswer }: Props) => {
  const { sizeMobile } = useResponsive();

  return (
    <ProductTable.Root
      heading={<h1 className={styles.label}>Itens para cotação:</h1>}
    >
      <ul className={styles['table-header']}>
        <li>Item</li>
        <li>Descrição</li>
        <li>Qtd. Solicitada</li>
      </ul>
      <div className={styles.answer}>
        {(quotationAnswer?.quotation?.items_quotation || []).map(
          (quotationItem, index) => {
            const correspondingAnswers =
              (quotationAnswer &&
                quotationAnswer.items_answer_quotation.filter(
                  answerItem =>
                    answerItem.item_quotation_id ===
                    quotationItem.id_item_quotation,
                )) ||
              [];

            return (
              <ItemQuotationList
                index={`${index + 1}`}
                name={quotationItem?.description ?? '----'}
                quantity={quotationItem?.quantity ?? '----'}
                responsive={sizeMobile ? 'mobile' : 'web'}
                items={correspondingAnswers.map(item => ({
                  brand: item.brand ?? '',
                  reference: item.reference ?? '',
                  unitValue: item.total ? `${item.total}` : '----',
                  quantity: item.quantity_asked
                    ? `${item.quantity_asked}`
                    : '----',
                }))}
                type="itemView"
                itemBrandType="finished"
                notOffer={correspondingAnswers.length === 0}
                key={quotationItem.id_item_quotation}
              />
            );
          },
        )}
      </div>
    </ProductTable.Root>
  );
};
