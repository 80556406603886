export const ERROR_MESSAGES = {
  required: 'Não esqueça dos campos obrigatórios.',
  requiredLicensePlate: 'informe a placa!',
  requiredName: 'Não esqueça do nome.',
  requiredDocument: 'Não esqueça do CPF/CNPJ',
  minLength: 'Verifique se os campos preenchidos estão corretos.',
  invalidDocument: 'Verifique se o CPF/CNPJ está correto.',
  invalidPhone: 'Verifique se o número está correto.',
  invalidYear: 'Verifique se o ano está correto. O Ano deve conter 4 dígitos.',
  invalidCEP: 'Este CEP não é válido.',
  invalidKM: 'Esse valor de KM não é válido.',
  invalidAddress: 'Esse endereço não está correto.',
  invalidUF: 'Essa UF não é válida.',
  invalidCity: 'Essa cidade não está correta.',
  invalidEmail: 'Esse e-mail não é válido.',
  invalidLicensePlate: 'Essa placa não é válida.',
  minServices: 'Informe ao menos um serviço.',
  invalidFuelValue: 'Informe um valor válido para o combustível',
};
