/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import axios from 'axios';

// export const baseURL = 'https://autocenter.mestresdaweb.io/';
export const baseURL = 'https://api.autocenterapp.com/';
// export const baseURL = 'http://localhost:3000/';
// export const baseURL = 'https://testserver.autocenterapp.com/';

const api = axios.create({
  baseURL,
});

api.interceptors.request.use(
  async config => {
    const accessToken = await localStorage.getItem('@AutoCenter: accessToken');
    if (accessToken) {
      config.headers!.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  error => Promise.reject(error),
);

export default api;
