import React, { CSSProperties } from 'react';
import styles from './row-item.module.css';

interface Props {
  children: React.ReactNode;
  style?: CSSProperties;
}

export const RowItem = ({ children, style }: Props) => {
  return (
    <div className={styles.item} style={style}>
      {children}
    </div>
  );
};
