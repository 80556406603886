import styles from './answered-quotations.module.css';
import { ListTable } from '../../../../../../shared/ListTable';
import { QuotedRepliesRow } from '../../components/QuotedRepliesRow';
import createdQuotationsIcon from '../../../../../../assets/icons/created-quotations.svg';
import { useAnsweredQuotations } from './useAnsweredQuotations';
import { ViewRepliesModal } from '../../components/modals/ViewReplies';
import { FinishModal } from '../../components/modals/FinishModal';
import { useChangeQuotationState } from '../../hooks/useChangeQuotationState';
import { BlockedQuotationAlert } from '../../../AnswerQuotation/components/modals/BlockedQuotationAlert';
import { useResponsive } from '../../../../../../hooks/useResponsive';
import { FilterComponent } from '../../../../../../shared/FilterComponent';

export const AnsweredQuotations = () => {
  const {
    // handleBlockModal,
    // isBlockModalOpen,
    isLoading,
    loadMoreData,
    page,
    quotationReplies,
    selectedQuotation,
    setSelectedQuotation,
    isViewAnswersQuotationModalOpen,
    setIsViewAnswersQuotationModalOpen,
    handleRowClick,
    handleChangeQuotationState,
    showBlockedQuotationMessage,
    handleShowBlockedQuotationMessage,
    filterType,
    filterValue,
    handleSearch,
    handleDateSearch,
    searchedQuotations,
    setFilterType,
  } = useAnsweredQuotations();

  const {
    changeQuotationState,
    isChangingQuotationModalOpen,
    isChangingQuotationState,
    handleFinishModal,
  } = useChangeQuotationState({
    onChangeState: handleChangeQuotationState,
  });
  const { sizeMobile } = useResponsive();

  return (
    <>
      <BlockedQuotationAlert
        isOpen={showBlockedQuotationMessage}
        onClose={() => handleShowBlockedQuotationMessage('close')}
      />
      {/* <BlockModal
        isOpen={isBlockModalOpen}
        onBlock={() => {
          changeQuotationState({
            id: selectedQuotation?.id_quotation ?? '',
            action: 'block',
          });
          handleBlockModal('close');
        }}
        onClose={() => {
          setSelectedQuotation(null);
          handleBlockModal('close');
        }}
        quotation={selectedQuotation}
      /> */}
      <ViewRepliesModal
        isOpen={isViewAnswersQuotationModalOpen}
        onClose={() => {
          setIsViewAnswersQuotationModalOpen(false);
          setSelectedQuotation(null);
        }}
        handleBlockQuotation={handleChangeQuotationState}
        reply={selectedQuotation}
      />
      <FinishModal
        isLoading={isChangingQuotationState}
        isOpen={isChangingQuotationModalOpen}
        onClose={() => {
          handleFinishModal('close');
          setSelectedQuotation(null);
        }}
        onFinish={() => {
          changeQuotationState({
            id: selectedQuotation?.id_quotation ?? '',
            action: 'finish',
          });
          handleFinishModal('close');
          setSelectedQuotation(null);
        }}
        quotation={selectedQuotation}
      />

      <div className={styles.heading}>
        <div className={styles.describe}>
          <img src={createdQuotationsIcon} alt="cotações criadas" />
          <p>Propostas recebidas</p>
        </div>
        <div className={styles.actions}>
          <FilterComponent
            handleDateSearch={handleDateSearch}
            handleSearch={handleSearch}
            handleFilterChange={(filter: string) =>
              setFilterType(filter as 'code' | 'date')
            }
            defaultValue="code"
            variant={filterType === 'code' ? 'searchbar' : 'date'}
            onlyNumber={filterType === 'code'}
            options={[
              {
                label: 'Número',
                value: 'code',
              },
              {
                label: 'Data',
                value: 'date',
              },
            ]}
          />
        </div>
      </div>

      <ListTable.Root
        isLoading={isLoading}
        isEmpty={!isLoading && quotationReplies.length === 0}
        notFound={
          filterValue.trim().length > 0 && searchedQuotations.length === 0
        }
        message="Nenhuma Cotação Encontrada"
      >
        <div className={styles.container}>
          <ListTable.Head
            style={{
              gridTemplateColumns: sizeMobile
                ? '2.5fr 2.5fr 2.5fr 1fr'
                : '1.125fr repeat(3, 1fr)',
              width: sizeMobile ? '100%' : '95%',
            }}
          >
            <ListTable.HeadItem>
              {sizeMobile ? 'N° de cotação' : 'Número de cotação'}
            </ListTable.HeadItem>
            {/* {!sizeMobile && <ListTable.HeadItem>Expiração</ListTable.HeadItem>} */}
            <ListTable.HeadItem>Data de Criação</ListTable.HeadItem>
            <ListTable.HeadItem>Data da Resposta</ListTable.HeadItem>
            <ListTable.HeadItem>Respostas</ListTable.HeadItem>
          </ListTable.Head>
          <ListTable.Body onScroll={() => loadMoreData(page)}>
            {/* if user has searched something it shows the response */}
            {(filterValue.trim().length > 0
              ? searchedQuotations
              : quotationReplies
            ).map(quotation => (
              <QuotedRepliesRow
                key={quotation.id_quotation}
                quotation={quotation}
                handleViewReplies={() =>
                  setIsViewAnswersQuotationModalOpen(true)
                }
                handleFinish={() => handleFinishModal('open')}
                isSelected={
                  !!selectedQuotation &&
                  selectedQuotation.id_quotation === quotation.id_quotation
                }
                handleShowBlockedMessage={handleShowBlockedQuotationMessage}
                onRowClick={() => handleRowClick(quotation)}
              />
            ))}
          </ListTable.Body>
        </div>
      </ListTable.Root>
    </>
  );
};
