import { AlertMessage } from '../../../../../shared/AlertMessage';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const AnswerIdDetectionModal = ({
  isOpen,
  onClose,
  onConfirm,
}: Props) => {
  return (
    <AlertMessage.Root
      isOpen={isOpen}
      onClose={onClose}
      title="Atençaõ"
      footer={
        <AlertMessage.Footer>
          <AlertMessage.ConfirmButton onClick={onConfirm}>
            Sim
          </AlertMessage.ConfirmButton>
          <AlertMessage.CancelButton
            onClick={onClose}
            style={{
              minWidth: '100px',
            }}
          >
            Não
          </AlertMessage.CancelButton>
        </AlertMessage.Footer>
      }
    >
      <AlertMessage.Body>
        <p>Resposta de cotação detectada. Deseja responder?</p>
      </AlertMessage.Body>
    </AlertMessage.Root>
  );
};
