import * as yup from 'yup';
import { fetchVehicleData } from './model';
import { ERROR_MESSAGES } from '../../../utils/validations/errorMessages';
import { validateLicensePlate } from '../../../utils/validations/licensePLateValidation';
import message from '../../../utils/message';

export const fetchVehicleDataByLicensePlateController = async (
  licensePlate: string,
) => {
  const formattedLicensePlate = licensePlate
    .replace('[^A-Z0-9]', '')
    .trim()
    .toLocaleUpperCase();
  const schema = yup
    .string()
    .length(7, ERROR_MESSAGES.invalidLicensePlate)
    .test(
      'tests if license plate has valid format',
      ERROR_MESSAGES.invalidLicensePlate,
      value => {
        if (!value) return false;
        return validateLicensePlate(value);
      },
    );
  try {
    await schema.validate(formattedLicensePlate);
    return fetchVehicleData(formattedLicensePlate);
  } catch (err) {
    if (err instanceof yup.ValidationError) {
      message(err.errors[0], 'warning');
    } else console.error(err);
  }
};
