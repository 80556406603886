import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApprovedItems, ApprovedQuotation } from './interfaces';
import { Quotation } from '../../@types/interface';

interface QuotationState {
  approvedQuotation: ApprovedQuotation | null;
  notAnsweredQuotationList: Quotation[];
  answeredQuotationList: Quotation[];
  finishedQuotationList: Quotation[];
}

const initialState: QuotationState = {
  approvedQuotation: null,
  notAnsweredQuotationList: [],
  answeredQuotationList: [],
  finishedQuotationList: [],
};

export const quotationSlice = createSlice({
  name: 'quotation',
  initialState,
  reducers: {
    setApprovedQuotationList: (
      state,
      action: PayloadAction<ApprovedQuotation | null>,
    ) => {
      state.approvedQuotation = action.payload;
    },
    addAnswerItem: (
      state,
      action: PayloadAction<{
        answer_quotation_id: string;
        newItem: ApprovedItems;
      }>,
    ) => {
      const { answer_quotation_id, newItem } = action.payload;

      if (!state.approvedQuotation) return;

      const correspondingAnswer =
        state.approvedQuotation.list_answers_approved.find(
          answer => answer.answer_quotation_id === answer_quotation_id,
        );

      if (!correspondingAnswer) return;

      const itemExists = correspondingAnswer.items.some(
        item =>
          item.item_answer_quotation_id === newItem.item_answer_quotation_id,
      );

      if (!itemExists) {
        correspondingAnswer.items.push(newItem);

        // update list of answers
        state.approvedQuotation.list_answers_approved =
          state.approvedQuotation.list_answers_approved.map(answer =>
            answer.answer_quotation_id ===
            correspondingAnswer.answer_quotation_id
              ? correspondingAnswer
              : answer,
          );
      }
    },
    editAnswerItem: (
      state,
      action: PayloadAction<{
        answer_quotation_id: string;
        itemId: string;
        newQuantityAsked: number;
      }>,
    ) => {
      const { answer_quotation_id, itemId, newQuantityAsked } = action.payload;
      if (state.approvedQuotation) {
        const currentList = state.approvedQuotation.list_answers_approved.map(
          answer => {
            if (answer.answer_quotation_id === answer_quotation_id) {
              return {
                ...answer,
                items: answer.items.map(item => {
                  if (item.item_answer_quotation_id === itemId) {
                    return { ...item, quantity_asked: newQuantityAsked };
                  }

                  return item;
                }),
              };
            }
            return answer;
          },
        );
        state.approvedQuotation.list_answers_approved = currentList;
      }
    },
    removeAnswer: (
      state,
      action: PayloadAction<{ answer_quotation_id: string }>,
    ) => {
      const { answer_quotation_id } = action.payload;
      if (answer_quotation_id) {
        const updatedAnswersList =
          state.approvedQuotation?.list_answers_approved.filter(
            answer => answer.answer_quotation_id !== answer_quotation_id,
          ) || [];

        state.approvedQuotation = {
          quotation_id: state.approvedQuotation?.quotation_id || '',
          list_answers_approved: updatedAnswersList,
        };
      }
    },
    removeAnswerItem: (
      state,
      action: PayloadAction<{ answer_quotation_id: string; itemId: string }>,
    ) => {
      const { answer_quotation_id, itemId } = action.payload;
      if (state.approvedQuotation) {
        const currentList = state.approvedQuotation.list_answers_approved.map(
          answer => {
            if (answer.answer_quotation_id === answer_quotation_id) {
              return {
                ...answer,
                items: answer.items.filter(
                  item => item.item_answer_quotation_id !== itemId,
                ),
              };
            }
            return answer;
          },
        );
        state.approvedQuotation.list_answers_approved = currentList;
      }
    },
    editAnswerRebuttal: (
      state,
      action: PayloadAction<{
        answer_quotation_id: string;
        updatedValue: string;
      }>,
    ) => {
      const { answer_quotation_id, updatedValue } = action.payload;
      if (state.approvedQuotation) {
        const currentList = state.approvedQuotation.list_answers_approved.map(
          answer => {
            if (answer.answer_quotation_id === answer_quotation_id) {
              return {
                ...answer,
                rebuttal: updatedValue,
              };
            }
            return answer;
          },
        );
        state.approvedQuotation.list_answers_approved = currentList;
      }
    },
    addAnsweredQuotations: (state, action: PayloadAction<Quotation[]>) => {
      const newAnsweredQuotationList = action.payload.filter(
        quotation =>
          !state.answeredQuotationList.some(
            previousQuotation =>
              previousQuotation.id_quotation === quotation.id_quotation,
          ),
      );
      state.answeredQuotationList = [
        ...state.answeredQuotationList,
        ...newAnsweredQuotationList,
      ];
    },
    addNotAnsweredQuotations: (state, action: PayloadAction<Quotation[]>) => {
      const newNotAnsweredQuotationList = action.payload.filter(
        quotation =>
          !state.notAnsweredQuotationList.some(
            previousQuotation =>
              previousQuotation.id_quotation === quotation.id_quotation,
          ),
      );
      state.notAnsweredQuotationList = [
        ...state.notAnsweredQuotationList,
        ...newNotAnsweredQuotationList,
      ].sort((a, b) => b.QUOTATION - a.QUOTATION);
    },
    addFinishedQuotations: (state, action: PayloadAction<Quotation[]>) => {
      const newFinishedQuotationList = action.payload.filter(
        quotation =>
          !state.finishedQuotationList.some(
            previousQuotation =>
              previousQuotation.id_quotation === quotation.id_quotation,
          ),
      );
      state.finishedQuotationList = [
        ...state.finishedQuotationList,
        ...newFinishedQuotationList,
      ];
    },
    removeAnsweredQuotation: (state, action: PayloadAction<string>) => {
      state.answeredQuotationList = state.answeredQuotationList.filter(
        quotation => quotation.id_quotation !== action.payload,
      );
    },

    setAnsweredQuotations: (state, action: PayloadAction<Quotation[]>) => {
      state.answeredQuotationList = action.payload;
    },
    setNotAnsweredQuotations: (state, action: PayloadAction<Quotation[]>) => {
      state.notAnsweredQuotationList = action.payload;
    },
    setFinishedQuotations: (state, action: PayloadAction<Quotation[]>) => {
      state.finishedQuotationList = action.payload;
    },
    updateAnsweredQuotation: (state, action: PayloadAction<Quotation>) => {
      const updatedAnsweredQuotationList = state.answeredQuotationList.map(
        quotation => {
          if (quotation.id_quotation === action.payload.id_quotation)
            return action.payload;
          return quotation;
        },
      );
      state.answeredQuotationList = updatedAnsweredQuotationList;
      // order by visualization
      // .sort((a, b) => {
      //   if (a.visualized && !b.visualized) return 1;
      //   if (!a.visualized && b.visualized) return -1;
      //   return 0;
      // });
    },
  },
});

export const {
  setApprovedQuotationList,
  addAnswerItem,
  editAnswerItem,
  removeAnswerItem,
  editAnswerRebuttal,
  addNotAnsweredQuotations,
  addAnsweredQuotations,
  addFinishedQuotations,
  removeAnsweredQuotation,
  setAnsweredQuotations,
  setNotAnsweredQuotations,
  setFinishedQuotations,
  updateAnsweredQuotation,
  removeAnswer,
} = quotationSlice.actions;

export const quotationReducer = quotationSlice.reducer;
