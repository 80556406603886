import { ItemAnswerQuotation } from '../../../../../../../../../@types/interface';
import styles from './answer-row-item.module.css';
import { QuantityField } from '../../../../QuantityField';

interface Props {
  item: ItemAnswerQuotation;
  isAnswered: boolean;
  defaultValue: string;
  setInvalid: (invalid: boolean) => void;
  isTrembleWarning: boolean;
}

export const AnswerRowItem = ({
  item,
  isAnswered,
  defaultValue,
  isTrembleWarning,
  setInvalid,
}: Props) => {
  return (
    <li className={`${styles.item} ${isAnswered ? `${styles.answered}` : ''}`}>
      <span className={styles['row-field']}>{item?.brand ?? ''}</span>
      <span className={styles['row-field']}>{item?.reference ?? ''}</span>
      <span className={styles['row-field']}>
        R${' '}
        {item?.total
          ? item.total.toLocaleString('pt-br', {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })
          : ''}
      </span>
      {isAnswered ? (
        <span className={styles['row-field']}>
          {item?.quantity_asked ?? ''}
        </span>
      ) : (
        <QuantityField
          answer_quotation_id={item?.answer_quotation_id ?? ''}
          item_answer_quotation_id={item?.id_item_answer_quotation ?? ''}
          name={item?.id_item_answer_quotation ?? ''}
          disabled={!item?.quantity_available}
          maxValue={item?.quantity_available ?? 0}
          unitValue={item?.total ?? 0}
          trembleWarning={isTrembleWarning}
          setInvalid={setInvalid}
          defaultValue={defaultValue}
        />
      )}
    </li>
  );
};
