import { useEffect, useState } from 'react';
import data from './version.json';

export const Version = () => {
  const [currentVersion, setCurrentVersion] = useState('');
  useEffect(() => {
    if (data && data.version) setCurrentVersion(data.version);
  }, []);

  return <div>{currentVersion ? <>Versão: {currentVersion}</> : ''}</div>;
};
