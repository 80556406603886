import { AnswerQuotation } from '../../../../../../../@types/interface';
import { useResponsive } from '../../../../../../../hooks/useResponsive';
import { ProductTable } from '../../../../../../../shared/ProductTable';
import styles from './view-row-answer-quotation.module.css';

interface Props {
  answersQuote: AnswerQuotation;
  idItem: string;
}
export const ViewRowAnswerQuotation = ({ answersQuote, idItem }: Props) => {
  const { sizeMobile } = useResponsive();
  const brandsAndReferenceItens = answersQuote.items_answer_quotation
    .filter(item => item.item_quotation_id === idItem)
    .sort((a, b) => {
      if (a.approved && !b.approved) {
        return -1;
      }
      if (!a.approved && b.approved) {
        return 1;
      }
      return 0;
    });

  return (
    <div className={styles.row}>
      {brandsAndReferenceItens.length !== 0 && (
        <ProductTable.Header
          style={{
            gridTemplateColumns: 'repeat(4, 1fr)',
            padding: sizeMobile ? '0.25rem .5rem' : '.25rem 2rem',
          }}
        >
          <span style={{ paddingLeft: sizeMobile ? '0' : '2rem' }}>
            Distribuidora
          </span>
          <span>{sizeMobile ? 'Marca/Ref' : 'Marca/Referência'}</span>
          <span>{sizeMobile ? 'Qtd. Dispo' : 'Qtd. Disponível'}</span>
          <span>Valor Unit.</span>
        </ProductTable.Header>
      )}
      {brandsAndReferenceItens.map(item => (
        <ProductTable.Row
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(4, 1fr)',
            width: '100%',
            padding: sizeMobile ? '1rem .5rem' : '1rem 2rem',
            backgroundColor: item.approved ? '#d9d9d9' : '#FEF2F2',
            marginBottom: '.1rem',
            fontSize: '.8rem',
            fontWeight: 'bold',
          }}
        >
          <span
            style={{
              paddingLeft: sizeMobile ? '0' : '2rem',
            }}
          >
            {answersQuote.workshop.fantasy_name}
          </span>
          <span>{item.brand || 'Não informou'}</span>
          <span>{item.quantity_available || 'Não informou'}</span>
          <span>
            {new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
              minimumFractionDigits: 2,
            }).format(item.total) || 'Não informou'}
          </span>
        </ProductTable.Row>
      ))}
    </div>
  );
};
