import { ReactNode } from 'react';
import styles from './navbar.module.css';

interface Props {
  children: ReactNode;
}

export const Navbar = ({ children }: Props) => {
  return (
    <div className={styles.overlay}>
      <nav className={styles.navbar}>
        <ul className={styles['navbar-options']}>{children}</ul>
      </nav>
    </div>
  );
};
