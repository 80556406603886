import { useRef } from 'react';
import { PageLayout } from '../../../../../../../shared/PageLayout';
import styles from './view-replies.module.css';
import { QuoteDescription } from './components/QuoteDescription';
import { AnswersList } from './components/AnswersList';
import { useViewReplies } from './useViewReplies';
import { Quotation } from '../../../../../../../@types/interface';
import { ApproveModal } from './components/ApproveModal';
import { Overlay } from '../../../../../../../shared/Overlay';
import { ConfirmAnswerModal } from '../../../../AnswerQuotation/components/modals/ConfirmAnswerModal';
import { ErrorModal } from '../../../../AnswerQuotation/components/modals/ErrorModal';
import { useChangeQuotationState } from '../../../hooks/useChangeQuotationState';
import { BlockModal } from '../BlockModal';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  reply: Quotation | null;
  handleBlockQuotation: (quotation: Quotation) => void;
}

export const ViewRepliesModal = ({
  isOpen,
  onClose,
  reply = null,
  handleBlockQuotation,
}: Props) => {
  const overlayRef = useRef<HTMLDivElement>(null);
  const {
    quotationReplies,
    handleApproveQuotation,
    handleCloseModal,
    isApprovingModalOpen,
    modalStatus,
    handleConfirmModal,
    isConfirmModalOpen,
    closeErrorModal,
    errorModalMessage,
    closeFinishModal,
    shouldFinishModal,
    answeredQuotationId,
    isTrembleWarning,
    handleInvalid,
    handleWhatsappMessage,
    handleApproveAnswerById,
    handleRejectAnswerById,
    isLoading,
  } = useViewReplies({
    selectedQuotation: reply,
    isOpen,
    onClose,
  });

  const { changeQuotationState } = useChangeQuotationState({
    onChangeState: handleBlockQuotation,
  });

  const container = overlayRef.current || document.body;

  if (isOpen)
    return (
      <Overlay ref={overlayRef}>
        <ErrorModal
          container={container}
          isOpen={errorModalMessage.trim().length !== 0}
          message={errorModalMessage}
          onClose={closeErrorModal}
        />
        <ApproveModal
          status={modalStatus}
          isOpen={isApprovingModalOpen}
          onClose={() => {
            handleCloseModal();
            if (modalStatus === 'success') {
              onClose();
            }
          }}
          container={container}
        />
        <ConfirmAnswerModal
          isOpen={isConfirmModalOpen}
          onClose={() => handleConfirmModal('close')}
          onConfirm={handleApproveQuotation}
          onCancel={() => handleConfirmModal('close')}
          container={container}
        />

        <BlockModal
          isOpen={shouldFinishModal}
          onClose={closeFinishModal}
          onBlock={() => {
            changeQuotationState({
              id: answeredQuotationId,
              action: 'finish',
            });
            closeFinishModal();
            onClose();
          }}
        />
        <PageLayout.Root
          title="Avaliar Propostas"
          sectionStyle={{
            background: 'var(--white)',
          }}
          handleNavigation={onClose}
        >
          <div className={styles.content}>
            <div>
              <QuoteDescription
                quotation={quotationReplies}
                onClose={onClose}
                container={container}
                isLoading={isLoading}
              />
              <AnswersList
                quotation={quotationReplies}
                handleClick={invalid => handleConfirmModal('open', invalid)}
                isTrembleWarning={isTrembleWarning}
                setInvalid={invalid => handleInvalid(invalid)}
                handleWhatsappMessage={handleWhatsappMessage}
                handleApproveAnswer={handleApproveAnswerById}
                handleRejectAnswer={handleRejectAnswerById}
                isLoading={isLoading}
              />
            </div>
          </div>
        </PageLayout.Root>
      </Overlay>
    );
  return null;
};
