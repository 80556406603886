import { Route, Routes as Switch } from 'react-router-dom';
import { Home } from '../../pages/common/Home';
import { LandingPage } from '../../pages/common/LandingPage/Pages';
import PrivateRoute from '../routesPrivate';
import { Profile } from '../../pages/common/Profile';
import { CreateQuotation } from '../../pages/common/Quotation/CreateQuotation';
import { AnswerQuotation } from '../../pages/common/Quotation/AnswerQuotation';
import { Version } from '../../version';

export const CommonRoutes = () => {
  return (
    <Switch>
      <Route path="/" element={<LandingPage />} />
      <Route path="/version" element={<Version />} />
      <Route path="/test" element={<h1>Ok 👍</h1>} />
      <Route
        path="/home"
        element={
          <PrivateRoute>
            <Home />
          </PrivateRoute>
        }
      />
      <Route
        path="/profile"
        element={
          <PrivateRoute>
            <Profile />
          </PrivateRoute>
        }
      />
      <Route
        path="/new-quotation"
        element={
          <PrivateRoute>
            <CreateQuotation />
          </PrivateRoute>
        }
      />
      <Route
        path="/answer-quotation"
        element={
          <PrivateRoute>
            <AnswerQuotation />
          </PrivateRoute>
        }
      />
    </Switch>
  );
};
