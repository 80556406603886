import { ChangeEvent, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Quote } from './useCreateQuotation';
import { VehicleResponse } from '../../../../../../../services/dataFetchers/VehicleDataFetcher/model';
import { getQuotationPaymentTypesController } from '../../../controller';
import { PaymentTypes } from '../../../interface';
import { Quotation } from '../../../../../../../@types/interface';

export interface FormValues {
  vehicleId: string;
  plate?: string;
  vehicle?: string;
  chassi?: string;
  year?: string;
  model?: string;
  quotes: Quote[];
  comment?: string;
  hours?: string;
  paymentMethod?: string;
}

const defaultValues = {
  vehicleId: '',
  chassi: '',
  model: '',
  plate: '',
  quotes: [
    {
      description: '',
      quantity: '',
      reference: '',
    },
  ],
  vehicle: '',
  year: '',
  comment: '',
  hours: '',
  paymentMethod: '',
};

const schema = yup.object().shape({
  vehicleId: yup.string(),
  plate: yup.string(),
  vehicle: yup.string(),
  chassi: yup.string(),
  year: yup.string(),
  model: yup.string(),
  quotes: yup
    .array()
    .of(
      yup.object().shape({
        description: yup.string().required('O campo descrição é obrigatório'),
        reference: yup.string(),
        quantity: yup.string().required('O campo quantidade é obrigatório'),
      }),
    )
    .min(1, 'informe ao menos um item'),
  comment: yup.string(),
  hours: yup.string(),
});

interface Props {
  defaultQuotation: Quotation | null;
}

export const useQuotationForm = ({ defaultQuotation }: Props) => {
  const {
    control,
    getValues,
    reset,
    trigger,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues,
    resolver: yupResolver(schema),
  });
  const {
    fields: quotes,
    append,
    remove,
  } = useFieldArray({ control, name: 'quotes' });
  const [imagesList, setImagesList] = useState<File[]>([]);
  const [paymentTypes, setPaymentTypes] = useState<PaymentTypes[]>([]);

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (files) {
      const newImage = files[0];
      setImagesList(previousImages => {
        if (previousImages.length < 3) {
          return [...previousImages, newImage];
        }
        return [...previousImages.slice(1), newImage];
      });
    }
  };

  const handleRemoveImage = (name: string) => {
    setImagesList(previousImages => {
      const listImageAfterRemove = previousImages.filter(
        image => image.name !== name,
      );
      return [...listImageAfterRemove];
    });
  };

  const handleAddRow = () => {
    return append({
      description: '',
      quantity: '',
      reference: '',
    });
  };

  const resetFormValues = () => {
    reset(defaultValues);
    setImagesList([]);
  };

  const handleVehicleFields = (vehicle: VehicleResponse) => {
    const formValues = getValues();
    reset({
      ...formValues,
      chassi: vehicle.chassi ?? '',
      model: vehicle.model ?? '',
      vehicle: vehicle.name ?? '',
      year: vehicle.year ?? '',
      vehicleId: vehicle.id_vehicle,
    });
  };

  const fetchPaymentTypes = async () => {
    const res = await getQuotationPaymentTypesController();

    if (res && res.options) {
      setPaymentTypes(res.options);
    }
  };

  useEffect(() => {
    if (defaultQuotation) {
      reset({
        vehicleId: defaultQuotation?.vehicle_id ?? '',
        chassi: defaultQuotation?.vehicle?.chassi ?? '',
        model: defaultQuotation?.vehicle?.model ?? '',
        plate: defaultQuotation?.vehicle?.license_plate ?? '',
        quotes: defaultQuotation.items_quotation,
        vehicle: defaultQuotation?.vehicle?.name ?? '',
        year: defaultQuotation?.vehicle?.year ?? '',
        comment: defaultQuotation?.comment ?? '',
      });
    } else {
      reset(defaultValues);
    }
  }, [defaultQuotation, reset]);

  useEffect(() => {
    fetchPaymentTypes();
  }, []);

  return {
    control,
    imagesList,
    getValues,
    resetFormValues,
    handleImageChange,
    handleRemoveImage,
    handleAddRow,
    reset,
    handleVehicleFields,
    quotes,
    errors,
    trigger,
    remove,
    paymentTypes,
  };
};
