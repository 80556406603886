import { useState } from 'react';
import { PlusSquare, MinusSquare, Timer } from '@phosphor-icons/react';
import { Input } from '../../../../../../../../../shared/Input';
import styles from './quote-description.module.css';
import { Textarea } from '../../../../../../../../../shared/Textarea';
import { AnswerQuotation } from '../../../../../../../../../@types/interface';
import { HorizontalBannerList } from '../../../../../../../../../shared/HorizontalBannerList';
import { useResponsive } from '../../../../../../../../../hooks/useResponsive';
import { useCountdownTimer } from '../../../../../../../../../hooks/useCountdownTimer';
import {
  formatDate,
  formatTime,
} from '../../../../../../../../../utils/dateFunctions';
import { maskCpfCnpj } from '../../../../../../../../../services/helpers/mask';

interface Props {
  answer: AnswerQuotation | null;
}

export const QuoteDescription = ({ answer }: Props) => {
  const [ViewMore, setViewMora] = useState(false);
  const { sizeMobile } = useResponsive();

  const { timer } = useCountdownTimer({
    expirationHours: answer?.quotation?.hours ?? 0,
    initialDate: answer?.quotation?.created_at ?? new Date(),
  });

  const handleDateTime = (value: Date) => {
    const newDate = new Date(value);
    const formattedDate = formatDate(newDate);
    const formattedTime = formatTime(newDate);
    return `${formattedDate} às ${formattedTime}`;
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>
        Cotação N° {answer?.quotation?.QUOTATION || ''}
      </h1>
      {answer && (
        <div className={styles.info}>
          {' '}
          <h3 className={styles.subtitle}>
            criada em{' '}
            {handleDateTime(answer?.quotation?.created_at || new Date())}
          </h3>
          {/* {timer.length > 0 && (
            <span className={styles.timer}>
              <Timer size={24} color="var(--error)" weight="bold" />{' '}
              {timer !== 'expired' ? `Expira em ${timer}` : 'Cotação expirada'}
            </span>
          )} */}
        </div>
      )}
      {sizeMobile && (
        <div className={styles.buttonViewMore}>
          {ViewMore ? (
            <PlusSquare
              onClick={() => setViewMora(prev => !prev)}
              size={32}
              color="#007a36"
            />
          ) : (
            <MinusSquare
              onClick={() => setViewMora(prev => !prev)}
              size={32}
              color="#007a36"
            />
          )}
        </div>
      )}

      <ul
        className={`${styles['info-list']} ${
          ViewMore && sizeMobile ? styles.viewMore : ''
        }`}
      >
        <li>
          <Input
            name=""
            label="Origem:"
            value={answer?.quotation?.workshop.fantasy_name ?? ''}
            style={{
              border: 'none',
              fontWeight: '600',
              color: '#000000',
            }}
            disabled
          />
          <Input
            name=""
            label="CPF/CNPJ:"
            value={
              answer?.quotation?.workshop.cnpj
                ? maskCpfCnpj(answer.quotation.workshop.cnpj)
                : ''
            }
            style={{
              border: 'none',
              fontWeight: '600',
              color: '#000000',
            }}
            disabled
          />
        </li>

        {answer?.quotation?.agent && (
          <Input
            name=""
            label="Vendedor:"
            value={answer?.quotation?.agent?.user?.name ?? ''}
            style={{
              border: 'none',
              fontWeight: '600',
              color: '#000000',
            }}
            disabled
          />
        )}
        <Input
          name=""
          label="Forma de Pagamento:"
          value={answer?.quotation?.type_payment ?? 'Não informado'}
          style={{
            border: 'none',
            fontWeight: '600',
            color: '#000000',
          }}
          disabled
        />
        <li>
          <Input
            name=""
            label="Placa:"
            value={answer?.quotation?.vehicle?.license_plate ?? ''}
            style={{
              border: 'none',
              fontWeight: '600',
              color: '#000000',
            }}
            disabled
          />
          <Input
            name=""
            label="Ano:"
            value={answer?.quotation?.vehicle?.year ?? ''}
            style={{
              border: 'none',
              fontWeight: '600',
              color: '#000000',
            }}
            disabled
          />
        </li>
        <li>
          <Input
            name=""
            label="Veículo:"
            value={answer?.quotation?.vehicle?.name ?? ''}
            style={{
              border: 'none',
              fontWeight: '600',
              color: '#000000',
            }}
            disabled
          />
        </li>
        <li>
          <Input
            name=""
            label="Chassi:"
            value={answer?.quotation?.vehicle?.chassi ?? ''}
            style={{
              border: 'none',
              fontWeight: '600',
              color: '#000000',
            }}
            disabled
          />
        </li>

        <li>
          <HorizontalBannerList
            bannerList={answer?.quotation?.banners ?? []}
            label="Imagens Anexadas:"
          />
        </li>
        <li>
          <Textarea
            name=""
            label="Observações:"
            value={answer?.quotation?.comment ?? ''}
            style={{
              border: 'none',
              fontWeight: '600',
              color: '#000000',
            }}
            disabled
          />
        </li>
      </ul>
    </div>
  );
};
