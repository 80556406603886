import { WarningCircle } from '@phosphor-icons/react';
import { useState } from 'react';
import styles from './answers-list.module.css';
import {
  AnswerQuotation,
  Quotation,
} from '../../../../../../../../../@types/interface';
import { AnswerItem } from '../AnswerItem';
import { Button } from '../../../../../../../../../shared/Button';
import { LittleAnswer } from '../LittleAnswer';
import handshake from '../../../../../../../../../assets/img/handshake.svg';
import { useResponsive } from '../../../../../../../../../hooks/useResponsive';
import { Loading } from '../../../../../../../../../shared/Loading';
import { AnswerListLoading } from '../AnwerListLoading';

export interface FormValues {
  answers_quotation: AnswerQuotation[];
}

interface Props {
  quotation: Quotation | null;
  handleClick: (invalid: boolean) => void;
  isTrembleWarning: boolean;
  setInvalid: (invalid: boolean) => void;
  handleWhatsappMessage: (
    phone: string,
    quotationNumber: Quotation | null,
  ) => void;
  handleApproveAnswer: (id: string) => void;
  handleRejectAnswer: (id: string) => void;
  isLoading: boolean;
}

export const AnswersList = ({
  quotation,
  handleClick,
  isTrembleWarning,
  setInvalid,
  handleWhatsappMessage,
  handleApproveAnswer,
  handleRejectAnswer,
  isLoading,
}: Props) => {
  const answers =
    quotation &&
    quotation.answers_quotation &&
    quotation.answers_quotation.filter(
      answer => answer.items_answer_quotation.length !== 0,
    );
  const availableAnswers = answers?.filter(answer => !answer.confirmed) ?? [];
  const notAvailableAnswers =
    quotation?.answers_quotation?.filter(answer => !answer.answered) ?? [];

  const { sizeMobile } = useResponsive();
  const [isInvalid, setIsInvalid] = useState(false);

  const handleNumberOfAnswersText = (numberOfAnswers: number) => {
    if (numberOfAnswers === 0) return `Nenhuma  nova oferta para sua cotação`;
    if (numberOfAnswers === 1) return `1 nova proposta`;
    return `${numberOfAnswers} novas propostas`;
  };

  const handleSwitchComponent = (
    data: AnswerQuotation,
    quotes: Quotation,
    index: number,
  ) => {
    if (sizeMobile) {
      return (
        <LittleAnswer
          key={data.id_answer_quotation}
          quotationItems={quotes.items_quotation}
          answer={data}
          setInvalid={invalid => {
            setInvalid(invalid);
            setIsInvalid(invalid);
          }}
          isTrembleWarning={isTrembleWarning}
          handleWhatsappMessage={phone =>
            handleWhatsappMessage(phone, quotation)
          }
          isAnswered={data.confirmed}
          handleApproveAnswer={handleApproveAnswer}
          handleRejectAnswer={handleRejectAnswer}
        />
      );
    }
    return (
      <AnswerItem
        key={data.id_answer_quotation}
        quotationItems={quotes.items_quotation}
        answer={data}
        index={index}
        setInvalid={invalid => {
          setInvalid(invalid);
          setIsInvalid(invalid);
        }}
        isTrembleWarning={isTrembleWarning}
        handleWhatsappMessage={phone => handleWhatsappMessage(phone, quotation)}
        isAnswered={data.confirmed}
        handleApproveAnswer={handleApproveAnswer}
        handleRejectAnswer={handleRejectAnswer}
      />
    );
  };
  return (
    <div className={styles.container}>
      {isLoading ? (
        <AnswerListLoading />
      ) : (
        <>
          <div className={styles.heading}>
            <h1 className={styles.title}>Propostas Recebidas:</h1>
            {!sizeMobile && (
              <div className={styles['answers-info']}>
                <WarningCircle size={24} weight="fill" />
                {handleNumberOfAnswersText(availableAnswers.length)}
              </div>
            )}
          </div>
          <div className={styles.wrapper}>
            <div className={styles['answers-list']}>
              {answers &&
                quotation &&
                answers.map((answer, index) => {
                  return handleSwitchComponent(answer, quotation, index);
                })}

              {answers && answers.length === 0 && (
                <div className={styles['empty-proposals']}>
                  <img src={handshake} alt="nenhuma proposta nova" />
                  <b>Todas as propóstas já foram respondidas!</b>
                </div>
              )}
            </div>

            {notAvailableAnswers && notAvailableAnswers.length > 0 && (
              <h3>Aguardando resposta de: </h3>
            )}
            <ul className={styles['workshops-list']}>
              {answers &&
                notAvailableAnswers.map(notAvailableAnswer => (
                  <li key={notAvailableAnswer.id_answer_quotation}>
                    {notAvailableAnswer.workshop.fantasy_name}
                  </li>
                ))}
            </ul>

            {answers && availableAnswers.length !== 0 && (
              <Button
                handleClick={() => handleClick(isInvalid)}
                variant="register"
                style={{
                  fontSize: '2rem',
                }}
              >
                Enviar Pedidos
              </Button>
            )}
          </div>
        </>
      )}
    </div>
  );
};
