import styles from './brand-info.module.css';

interface Props {
  responsive: 'mobile' | 'web';
  brand: string;
  reference: string;
}

export const BrandInfo = ({ responsive, brand, reference }: Props) => {
  if (responsive === 'mobile') {
    return (
      <div className={`${styles['brand-info']} ${styles.mobile}`}>
        <b>Marca / Referência </b>
        <div className={styles.info}>
          <span className={styles.brand}>{brand} </span> /{' '}
          <span className={styles.reference}> {reference}</span>
        </div>
      </div>
    );
  }

  return (
    <div className={`${styles['brand-info']} ${styles.web}`}>
      <span>
        <b>Marca: </b>
        <span className={styles.brand}>{brand}</span>
      </span>
      <span>
        <b>Referência: </b>
        <span className={styles.reference}>{reference}</span>
      </span>
    </div>
  );
};
