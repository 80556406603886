import { Controller, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { Modal } from '../../../../../../../shared/Modal';
import { Textarea } from '../../../../../../../shared/Textarea';
import { Input } from '../../../../../../../shared/Input';
import styles from './view-created-quotation.module.css';
import { ProductTable } from '../../../../../../../shared/ProductTable';
import { Separator } from '../../../../../../../shared/Separator';
import { Banner, Quotation } from '../../../../../../../@types/interface';
import { useResponsive } from '../../../../../../../hooks/useResponsive';
import { ViewHeaderAnswerQuotation } from '../ViewHeaderAnswerQuotation';
import { BannerViewModal } from '../../../../../../../shared/BannerViewModal';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  quotation: Quotation | null;
}

interface Quote {
  description: string;
  reference: string;
  quantity: string;
  value: string;
  availableQuantity: string;
}

export interface FormValues {
  plate?: string;
  vehicle?: string;
  chassi?: string;
  year?: string;
  model?: string;
  quotes: Quote[];
  observation?: string;
  paymentMethod: string;
}

export const ViewCreatedQuotation = ({ isOpen, onClose, quotation }: Props) => {
  const { sizeMobile } = useResponsive();
  const [selectedBanner, setSelectedBanner] = useState<Banner | null>(null);
  const { control, reset } = useForm<FormValues>({
    defaultValues: {
      chassi: '',
      model: '',
      plate: '',
      vehicle: '',
      year: '',
      paymentMethod: '',
      quotes: [
        {
          description: '',
          quantity: '',
          reference: '',
          availableQuantity: '',
          value: '',
        },
      ],
      observation: '',
    },
  });

  useEffect(() => {
    if (quotation) {
      reset({
        chassi: quotation?.vehicle?.chassi ?? '',
        model: quotation?.vehicle?.model ?? '',
        plate: quotation?.vehicle?.license_plate ?? '',
        vehicle: quotation?.vehicle?.name ?? '',
        year: quotation?.vehicle?.year ?? '',
        quotes: quotation.items_quotation.map(item => ({
          description: item?.description ?? '',
          quantity: item?.quantity ?? '',
          reference: item?.reference ?? '',
          availableQuantity: '',
          value: '',
        })),
        observation: quotation?.comment ?? '',
        paymentMethod: quotation?.type_payment ?? 'Não informado',
      });
    }
  }, [quotation, reset]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={`Visualizar Cotação - N° ${quotation?.QUOTATION || ''}`}
      size={sizeMobile ? 'full' : 'large'}
    >
      <BannerViewModal
        banner={selectedBanner}
        isOpen={!!selectedBanner}
        onClose={() => setSelectedBanner(null)}
      />
      <div className={styles['vehicle-info']}>
        <div className={styles.row}>
          <Controller
            name="plate"
            control={control}
            render={({ field: { name, onChange, value } }) => (
              <Input
                name={name}
                label="Placa:"
                value={value}
                handleChange={onChange}
                disabled
                style={{ fontWeight: '600', color: '#000000' }}
              />
            )}
          />
          <Controller
            name="vehicle"
            control={control}
            render={({ field: { name, onChange, value } }) => (
              <Input
                name={name}
                label="Veículo:"
                value={value}
                handleChange={onChange}
                disabled
                style={{ fontWeight: '600', color: '#000000' }}
              />
            )}
          />
          <Controller
            name="chassi"
            control={control}
            render={({ field: { name, onChange, value } }) => (
              <Input
                name={name}
                label="Chassi:"
                value={value}
                handleChange={onChange}
                disabled
                style={{ fontWeight: '600', color: '#000000' }}
              />
            )}
          />
        </div>
        <div className={styles.row}>
          <Controller
            name="year"
            control={control}
            render={({ field: { name, onChange, value } }) => (
              <Input
                name={name}
                label="Ano:"
                value={value}
                handleChange={onChange}
                disabled
                style={{ fontWeight: '600', color: '#000000' }}
              />
            )}
          />
          <Controller
            name="model"
            control={control}
            render={({ field: { name, onChange, value } }) => (
              <Input
                name={name}
                label="Modelo:"
                value={value}
                handleChange={onChange}
                disabled
                style={{ fontWeight: '600', color: '#000000' }}
              />
            )}
          />
        </div>
        <Controller
          name="paymentMethod"
          control={control}
          render={({ field: { name, onChange, value } }) => (
            <Input
              name={name}
              label="Forma de Pagamento:"
              value={value}
              handleChange={onChange}
              disabled
              style={{ fontWeight: '600', color: '#000000' }}
            />
          )}
        />
        <div className={styles.observation}>
          <Controller
            name="observation"
            control={control}
            render={({ field: { name, onChange, value } }) => (
              <Textarea
                name={name}
                handleChange={onChange}
                value={value}
                label="Observações:"
                disabled
                style={{
                  height: '5rem',
                  fontWeight: '500',
                  color: '#000000',
                }}
              />
            )}
          />
        </div>
      </div>

      <ProductTable.Root
        heading={<h1 className={styles.label}>Itens para cotação:</h1>}
      >
        <div>
          <ProductTable.Header
            style={{
              gridTemplateColumns: sizeMobile
                ? '0.25fr repeat(2, 1.5fr) 1fr'
                : '0.25fr repeat(3, 1fr)',
              padding: '0.25rem 1rem',
            }}
          >
            <span style={{ fontSize: '1rem' }}>Item</span>
            <span style={{ fontSize: '1rem' }}>Descrição</span>
            <span style={{ fontSize: '1rem' }}>
              {sizeMobile ? 'Qtd. solic' : 'Qtd. solicitada'}
            </span>
            <span style={{ fontSize: '1rem', justifySelf: 'center' }}>
              {sizeMobile ? 'Ref' : 'Referência'}
            </span>
          </ProductTable.Header>
          <ProductTable.Body>
            {quotation &&
              quotation?.items_quotation.map((quote, index) => (
                <div key={quote.id_item_quotation}>
                  <ProductTable.Row
                    style={{
                      backgroundColor: '#EFF0F2',
                      padding: sizeMobile ? '1.5rem 1rem' : '1.5rem 0',
                      marginTop: '0.5rem',
                      gridTemplateColumns: sizeMobile
                        ? '0.25fr repeat(2, 1.5fr) 1fr'
                        : '0.25fr repeat(3, 1fr)',
                    }}
                  >
                    <span
                      style={{
                        fontWeight: 'bold',
                        paddingLeft: sizeMobile ? '0' : '2rem',
                      }}
                    >
                      {index + 1}.
                    </span>
                    <Controller
                      name={`quotes.${index}.description`}
                      control={control}
                      render={({ field: { value } }) => <span>{value}</span>}
                    />
                    <Controller
                      name={`quotes.${index}.quantity`}
                      control={control}
                      render={({ field: { value } }) => <span>{value}</span>}
                    />
                    <Controller
                      name={`quotes.${index}.reference`}
                      control={control}
                      render={({ field: { value } }) => (
                        <span style={{ justifySelf: 'center' }}>{value}</span>
                      )}
                    />
                  </ProductTable.Row>
                  {quotation && (
                    <ViewHeaderAnswerQuotation
                      quotationReplies={quotation}
                      idItem={quote.id_item_quotation}
                    />
                  )}
                </div>
              ))}
          </ProductTable.Body>
        </div>
      </ProductTable.Root>

      <Separator
        theme="square"
        style={{
          margin: '2rem 0',
        }}
      />

      <h1 className={styles.label}>Autopeças da Cotação:</h1>
      <ul className={styles['workshops-list']}>
        {quotation &&
          quotation.answers_quotation &&
          quotation.answers_quotation.map((answer, index) => (
            <li key={answer.id_answer_quotation}>
              {answer.workshop.fantasy_name}
            </li>
          ))}
        {quotation &&
          quotation.answers_quotation &&
          quotation.answers_quotation.length === 0 && (
            <span>Nenhuma autopeça identificada</span>
          )}
      </ul>

      {quotation && quotation.banners && quotation.banners.length > 0 && (
        <h1 className={styles.label}>Imagens da Cotação:</h1>
      )}
      <ul className={styles['banners-list']}>
        {quotation &&
          quotation.banners &&
          quotation.banners.map(banner => (
            <li key={banner.id_banner}>
              <button type="button" onClick={() => setSelectedBanner(banner)}>
                <img src={banner.img_url} alt={banner.title ?? ''} />
              </button>
            </li>
          ))}
      </ul>
    </Modal>
  );
};
